import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
} from '@mui/material';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import { useActivePipelineActivityWorkflowIdSelector } from 'store/modules/assets/assets.selector';
import { setActivePipelineActivityWorkflowId } from 'store/modules/assets/assets.reducer';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import {
  StyledTableBodyCell,
  StyledTableHeaderCell,
} from 'components/table/styles';
import { useIsPlainModalOpenSelector } from 'store/modules/modals/modals.selector';
import { TableRowWithSpinner } from 'components/table/table-components/table-components';
import { ModalContainer } from 'components/modals/modal-container/modal-container';

export const AssetsRunsModal = () => {
  const dispatch = useDispatch();
  const workflowId = useActivePipelineActivityWorkflowIdSelector();

  const isAssetsRunsOpenModal = useIsPlainModalOpenSelector(
    PlainModals.ASSETS_RUNS_MODAL,
  );

  const apiContext = useContext(ApiContext);

  const { data, isLoading } =
    apiContext.baseApi?.useGetAssetsPipelineActivityRunsDetailsQuery(
      workflowId,
    );

  const handleCloseModal = () => {
    dispatch(setActivePipelineActivityWorkflowId(null));
    dispatch(closeModalByNameAction(PlainModals.ASSETS_RUNS_MODAL));
  };

  return (
    <ModalContainer
      modalHeight="400px"
      modalWidth="700px"
      title="Group/Team"
      isOpenModal={isAssetsRunsOpenModal}
      handleCloseModal={handleCloseModal}
    >
      <TableContainer sx={{ marginTop: '25px', maxHeight: '750px' }}>
        <Table
          stickyHeader
          sx={{ minWidth: '550px', backgroundColor: 'transparent' }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell>Runs</StyledTableHeaderCell>
              <StyledTableHeaderCell>Pass or Fail</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && data?.length ? (
              data?.map((item, index) => (
                <TableRow key={index}>
                  <StyledTableBodyCell align="left">
                    Run {item.number_of_runs}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell align="left">
                    {item.pass_or_fail}
                  </StyledTableBodyCell>
                </TableRow>
              ))
            ) : (
              <TableRowWithSpinner
                colCount={2}
                height="200px"
                spinnerSize="30px"
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalContainer>
  );
};
