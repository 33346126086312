import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

import { CustomSvgIcon } from '../../svg-icon/svg-icon';
import { FontSizes } from 'constants/font-sizes';
import { Colors } from 'constants/colors';
import { fontSecondary } from 'constants/font-family';

interface Props {
  children?: ReactNode;
  iconSize?: string;
  title: string;
}

export const PageHeader = ({ children, title, iconSize }: Props) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {children && (
          <CustomSvgIcon
            sx={{
              marginBottom: '20px',
              fontSize: `${iconSize ? iconSize : FontSizes.fontSize14}`,
            }}
          >
            {children}
          </CustomSvgIcon>
        )}
        <Typography
          variant="h1"
          sx={{ color: Colors.white, fontFamily: fontSecondary }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
