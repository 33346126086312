import React from 'react';
import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  TableRow,
  Table,
  TableBody,
  TableHead,
  TableContainer,
} from '@mui/material';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';

import {
  StyledTableBodyCell,
  StyledTableHeaderCell,
} from 'components/table/styles';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
// import { TableCellWithLink } from 'components/table/table-components/table-components';
// import { paths } from 'constants/routes';

export const RepositoryImportersModal = () => {
  const dispatch = useDispatch();
  const metaData = useGetModalMetaData();

  //@ts-ignore
  const importers: any[] = metaData?.importers;

  const isOpenModal = useIsPlainModalOpenSelector(
    PlainModals.REPOSITORY_IMPORTERS_MODAL,
  );

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.REPOSITORY_IMPORTERS_MODAL));
  };

  return (
    <ModalContainer
      isOpenModal={isOpenModal}
      title="Importers"
      handleCloseModal={handleCloseModal}
      modalWidth="700px"
      modalHeight="400px"
    >
      <TableContainer sx={{ marginTop: '25px', maxHeight: '750px' }}>
        <Table
          stickyHeader
          sx={{ minWidth: '550px', backgroundColor: 'transparent' }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell align="left">
                Package Name
              </StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">
                Importer Name
              </StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">
                Importer Email
              </StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">
                Imported Date
              </StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {importers?.map((item, index) => (
              <TableRow key={index}>
                <StyledTableBodyCell>{item.parent}</StyledTableBodyCell>
                <StyledTableBodyCell>
                  {item.importer_name || ''}
                </StyledTableBodyCell>
                <StyledTableBodyCell>
                  {item.importer_email || ''}
                </StyledTableBodyCell>
                <StyledTableBodyCell>
                  {item.imported_date &&
                  item.imported_date.length > 0 &&
                  dayjs(item.imported_date).format('L LT')
                    ? dayjs(item.imported_date).format('L LT')
                    : ''}
                </StyledTableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalContainer>
  );
};
