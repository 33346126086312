import githubImg from 'assets/icons/github.svg';
import azureDevopsImg from 'assets/icons/azuredevops.svg';
import jiraImg from 'assets/icons/jira.svg';
import googleDirectoryImg from 'assets/icons/google-directory.svg';
import jenkinsImg from 'assets/icons/jenkins.svg';
import oktaImg from 'assets/icons/okta.svg';
import slackImg from 'assets/icons/slack.svg';
import gitlabImg from 'assets/icons/gitlab.svg';
import azureAdImg from 'assets/icons/azure-ad.svg';
import bitbucketImg from 'assets/icons/bitbucket.svg';
import circleCiImg from 'assets/icons/circle-ci.svg';
import emailImg from 'assets/icons/email-policy-builder.svg';
import splunkImg from 'assets/icons/splunk.svg';
import teamsImg from 'assets/icons/microsoft-teams.svg';
import flockImg from 'assets/icons/flock.svg';
import snykImg from 'assets/icons/snyk.svg';
import blackduckImg from 'assets/icons/blackduck.svg';
import compassImg from 'assets/icons/compass.svg';
import { InputsSchemas } from 'pages/connectors/types';
import { AssetsRepositoriesItemRes } from 'store/apis/types';

export const getIntegrationImage = (source: InputsSchemas | '') => {
  switch (source) {
    case InputsSchemas.GITHUB:
      return githubImg;
    case InputsSchemas.GITLAB:
      return gitlabImg;
    case InputsSchemas.OKTA:
      return oktaImg;
    case InputsSchemas.JIRA:
      return jiraImg;
    case InputsSchemas.AZURE_AD:
      return azureAdImg;
    case InputsSchemas.COMPASS:
      return compassImg;
    case InputsSchemas.BITBUCKET:
      return bitbucketImg;
    case InputsSchemas.JENKINS:
      return jenkinsImg;
    case InputsSchemas.CIRCLE_CI:
      return circleCiImg;
    case InputsSchemas.AZURE_DEVOPS:
      return azureDevopsImg;
    case InputsSchemas.GOOGLE_DIR:
      return googleDirectoryImg;
    case InputsSchemas.SLACK:
      return slackImg;
    case InputsSchemas.BLACKDUCK:
      return blackduckImg;
    case InputsSchemas.FLOCK:
      return flockImg;
    case InputsSchemas.TEAMS:
      return teamsImg;
    case InputsSchemas.EMAIL:
      return emailImg;
    case InputsSchemas.SPLUNK:
      return splunkImg;
    case InputsSchemas.SNYK:
      return snykImg;
    default:
      return githubImg;
  }
};

export const getRepositoryStatus = (
  item: AssetsRepositoriesItemRes,
): string => {
  if (item.archived) return 'Archived';
  if (item.disabled) return 'Disabled';
  if (item.inactive) return 'Stale';
  return 'Active';
};
