import { Link } from 'react-router-dom';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';

import { Colors } from 'constants/colors';
import { Sizes } from 'constants/sizes';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';

export const openedMixin = (theme: Theme): CSSObject => ({
  width: Sizes.menuWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: Colors.blackPearl,
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: Colors.blackPearl,
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: Sizes.menuWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const DrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '15px 20px',
  justifyContent: 'space-between',
  backgroundColor: 'rgba(0, 0, 0, 0.31)',
  position: 'relative',
}));

export const LogoContainer = styled('div')<{ textOpacity: number }>(
  ({ textOpacity }) => ({
    fontFamily: fontSecondary,
    display: 'flex',
    '& > span': {
      fontSize: FontSizes.fontSize10,
      color: Colors.white,
      opacity: textOpacity,
    },
  }),
);

export const Logo = styled('img')(() => ({
  width: '15px',
  height: '15px',
  marginRight: '8px',
  cursor: 'pointer',
}));

export const NavItemText = styled('span')<{ opacity?: number }>(
  ({ opacity }) => ({
    fontSize: FontSizes.fontSize15,
    color: Colors.white,
    opacity,
  }),
);

export const NavChildItemText = styled(NavItemText)<{ isActive: boolean }>(
  ({ isActive }) => ({
    color: isActive ? Colors.purple : Colors.white,
  }),
);

export const ExpandArrowContainer = styled('div')(() => ({
  color: Colors.white,
  marginLeft: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& > svg': {
    fontSize: FontSizes.fontSize17,
  },
}));

export const MenuItemWrapper = styled('div')<{
  isBottomItem?: boolean;
}>(({ isBottomItem }) => ({
  marginTop: isBottomItem ? 'auto' : '10px',
}));

export const VersionInfo = styled('span')(() => ({
  color: Colors.white,
  paddingLeft: '23px',
  fontSize: FontSizes.fontSize10,
  fontFamily: fontSecondary,
  opacity: 0.3,
  position: 'absolute',
  left: '20px',
  bottom: '3px',
}));

export const CustomMenuIcon = styled(MenuItem)<{ isActive: boolean }>(
  ({ isActive }) => ({
    backgroundColor: isActive ? 'rgba(0, 0, 0, 0.31)' : 'transparent',
    borderRadius: '7px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.31)',
    },
  }),
);

export const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',
}));
