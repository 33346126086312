import { ReactNode, useState } from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';

import {
  TableRowEmptyState,
  TableRowWithSpinner,
} from '../table-components/table-components';
import { Colors } from 'constants/colors';
import { StyledTableBodyCell, StyledTableHeaderCell } from '../styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export interface ExtraCellsObject {
  [key: string]: (item: any) => ReactNode;
}

export interface TableColumnProps {
  id: string;
  title: string;
  withSort?: boolean;
  position?: 'center' | 'left' | 'right' | 'inherit' | 'justify';
  isDate?: boolean;
  dateFormat?: string;
  sortColumn?: string;
  customComponent?: (data: any) => ReactNode;
}

export interface TablePageProps {
  tableHeight?: string;
  isListLoading: boolean;
  isListErrored: boolean;
  isEmptyList: boolean;
  isListFetching: boolean;
  list: Record<string, any>[];
  lastElementRef?: (node: HTMLTableRowElement) => void;
  sortHandler?: (field: string) => void;
  tableColumns: TableColumnProps[];
  extraCellsRenderObj?: ExtraCellsObject;
  onRowClick?: (item: any) => void;
}

export const TableComponent = ({
  isListLoading,
  list,
  isListFetching,
  isListErrored,
  isEmptyList,
  lastElementRef,
  sortHandler,
  tableColumns,
  extraCellsRenderObj,
  tableHeight,
  onRowClick,
}: TablePageProps) => {
  const borderBottomStyle = `1px solid ${Colors.plantation}`;

  const [currentSelectedRow, setCurrentSelectedRow] = useState<
    number | undefined
  >(-1);

  const renderTableHeaderContent = () => {
    return (
      <TableRow>
        {tableColumns.map((column) => (
          <StyledTableHeaderCell
            sx={{
              px: tableColumns[0]?.id === 'expand' ? '0px' : null,
              maxWidth: column.id === 'overprivilege_score' ? '75px' : null,
              whiteSpace: column.id === 'overprivilege_score' ? 'normal' : null,
              textAlign: column.id === 'overprivilege_score' ? 'center' : null,
            }}
            key={column.id}
            withSort={column.withSort}
            alignSortArrow={column.id === 'overprivilege_score'}
            clickSortHandler={() =>
              sortHandler && sortHandler(column.sortColumn || column.id)
            }
            align={column.position || 'center'}
          >
            {column.title}
          </StyledTableHeaderCell>
        ))}
      </TableRow>
    );
  };

  const renderTableBodyContent = () => {
    if (isListLoading) {
      return (
        <TableRowWithSpinner
          colCount={tableColumns.length}
          height="200px"
          spinnerSize="30px"
        />
      );
    }

    if (isListErrored) {
      return (
        <TableRowEmptyState
          colCount={tableColumns.length}
          height="200px"
          title="Error"
        />
      );
    }

    if (list && !isEmptyList) {
      return (
        <>
          {list.map((item, index) => (
            <>
              <TableRow
                sx={{
                  backgroundColor:
                    currentSelectedRow === index ? Colors.blackPearl : null,
                  '&.MuiTableRow-hover:hover': {
                    backgroundColor: Colors.blackPearl,
                  },
                }}
                hover
                key={index}
                ref={list.length === index + 1 ? lastElementRef : null}
                onClick={() => (onRowClick ? onRowClick(item) : null)}
              >
                {tableColumns.map((column) => {
                  if (column.customComponent) {
                    return (
                      <StyledTableBodyCell
                        key={column.id}
                        align={column.position || 'center'}
                        sx={{
                          px: tableColumns[0]?.id === 'expand' ? '0px' : null,
                          borderBottom:
                            currentSelectedRow === index
                              ? '0px'
                              : borderBottomStyle,
                        }}
                      >
                        {column.customComponent(item)}
                      </StyledTableBodyCell>
                    );
                  }

                  if (extraCellsRenderObj && extraCellsRenderObj[column.id]) {
                    return (
                      <StyledTableBodyCell
                        key={column.id}
                        align="left"
                        width={
                          column.id === 'expand' || column.id === 'count'
                            ? '32px'
                            : 'auto'
                        }
                        sx={{
                          borderBottom:
                            currentSelectedRow === index
                              ? '0px'
                              : borderBottomStyle,
                          px:
                            typeof currentSelectedRow === 'number' ||
                            tableColumns[0]?.id === 'expand'
                              ? '0px'
                              : null,
                        }}
                        onClick={() => {
                          if (column.id === 'expand') {
                            setCurrentSelectedRow(
                              index === currentSelectedRow ? -1 : index,
                            );
                          }
                        }}
                      >
                        {column.id === 'expand' ? (
                          <>
                            {index === currentSelectedRow ? (
                              <ArrowDropDownIcon
                                sx={{ color: Colors.white, cursor: 'pointer' }}
                              />
                            ) : (
                              <ArrowRightIcon
                                sx={{ color: Colors.white, cursor: 'pointer' }}
                              />
                            )}
                          </>
                        ) : (
                          extraCellsRenderObj[column.id](item)
                        )}
                      </StyledTableBodyCell>
                    );
                  }

                  if (column.isDate) {
                    return (
                      <StyledTableBodyCell
                        key={column.id}
                        align={column.position || 'center'}
                        sx={{
                          borderBottom:
                            currentSelectedRow === index
                              ? '0px'
                              : borderBottomStyle,
                          paddigLeft:
                            typeof currentSelectedRow === 'number' ||
                            tableColumns[0]?.id === 'expand'
                              ? '0px'
                              : null,
                          px: tableColumns[0]?.id === 'expand' ? '0px' : null,
                        }}
                      >
                        {item[column.id]
                          ? dayjs(item[column.id]).format(
                              column.dateFormat
                                ? column.dateFormat
                                : 'MM/DD/YYYY',
                            )
                          : '-'}
                      </StyledTableBodyCell>
                    );
                  }

                  return (
                    <StyledTableBodyCell
                      key={column.id}
                      align={column.position || 'center'}
                      sx={{
                        px: tableColumns[0]?.id === 'expand' ? '0px' : null,
                        borderBottom:
                          currentSelectedRow === index
                            ? '0px'
                            : borderBottomStyle,
                      }}
                    >
                      {item[column.id]}
                    </StyledTableBodyCell>
                  );
                })}
              </TableRow>
              {typeof currentSelectedRow === 'number' &&
              currentSelectedRow === index ? (
                <TableRow
                  sx={{
                    backgroundColor:
                      currentSelectedRow === index ? Colors.blackPearl : null,
                  }}
                >
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={tableColumns?.length}
                  >
                    <Collapse
                      in={currentSelectedRow === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      {extraCellsRenderObj?.expand(item)}
                    </Collapse>
                  </TableCell>
                </TableRow>
              ) : null}
            </>
          ))}
          {!isListLoading && isListFetching && (
            <TableRowWithSpinner
              colCount={tableColumns.length}
              spinnerSize="15px"
              height="unset"
            />
          )}
        </>
      );
    } else {
      return (
        <TableRowEmptyState
          colCount={tableColumns.length}
          height="200px"
          title="Nothing to display"
        />
      );
    }
  };

  return (
    <TableContainer
      sx={{
        marginTop: '15px',
        maxHeight: '80vh',
        height: tableHeight,
        maxWidth: 'calc(100vw)',
      }}
    >
      <Table
        stickyHeader
        sx={{ minWidth: '550px', backgroundColor: 'transparent' }}
        size="small"
      >
        <TableHead>{renderTableHeaderContent()}</TableHead>
        <TableBody>{renderTableBodyContent()}</TableBody>
      </Table>
    </TableContainer>
  );
};
