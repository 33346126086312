import { ModalContainer } from '../../../../components/modals/modal-container/modal-container';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from '../../../../store/modules/modals/modals.selector';
import {
  closeModalByNameAction,
  PlainModals,
} from '../../../../store/modules/modals/modals.reducer';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Colors } from '../../../../constants/colors';
import { FontSizes } from '../../../../constants/font-sizes';
import dayjs from 'dayjs';

export const NotesViewerModal = () => {
  const dispatch = useDispatch();
  const { notes } = useGetModalMetaData() as unknown as {
    notes: {
      content: string;
      created_by: {
        name: string;
        email: string;
      };
      triggered_at: Date;
    };
  };

  const isNotesViewerOpenModal = useIsPlainModalOpenSelector(
    PlainModals.NOTES_VIEWER_MODAL,
  );
  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.NOTES_VIEWER_MODAL));
  };
  return (
    <ModalContainer
      isOpenModal={isNotesViewerOpenModal}
      handleCloseModal={handleCloseModal}
      title={'Notes'}
      closeModalTitle={'Close'}
      modalHeight={'300px'}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(3, 28, 32, 0.5)',
          color: 'white',
          height: '200px',
          width: '350px',
          marginTop: '20px',
          marginLeft: '-20px',
          padding: '5px',
          border: '1px solid rgba(255, 255, 255, 0.3)',
        }}
      >
        {notes.content}
      </Box>
      <Box
        sx={{
          marginTop: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '350px',
          marginLeft: '-20px',
        }}
      >
        <Box>
          <Typography
            sx={{
              color: Colors.whiteTransparent05,
              fontSize: FontSizes.fontSize12,
            }}
          >
            Created At
          </Typography>
          <Typography>
            {dayjs(notes.triggered_at).format('DD MMMM, hh:mm')}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: Colors.whiteTransparent05,
              fontSize: FontSizes.fontSize12,
            }}
          >
            Created By
          </Typography>
          <Typography>{notes.created_by?.name}</Typography>
        </Box>
      </Box>
    </ModalContainer>
  );
};
