import { FC, ReactNode } from 'react';
import { Alert, IconButton, Stack } from '@mui/material';
import { Check, Close, Info } from '@mui/icons-material';
import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';

interface Props {
  type: 'success' | 'info' | 'error';
  title: string | ReactNode;
}

export const ToastMessage: FC<Props> = ({ type, title }) => {
  return (
    <Stack sx={{ width: '100%', position: 'relative' }}>
      <Alert
        variant="filled"
        severity={type}
        iconMapping={{
          error: (
            <Close sx={{ width: '12px', height: '12px', color: Colors.red }} />
          ),
          success: (
            <Check
              sx={{ width: '12px', height: '12px', color: Colors.green }}
            />
          ),
          info: (
            <Info sx={{ width: '12px', height: '12px', color: Colors.white }} />
          ),
        }}
        sx={{
          backgroundColor: Colors.blackPearl,
          alignItems: 'center',
          display: 'flex',
          fontSize: FontSizes.fontSize12,
        }}
      >
        <>
          {title}
          <IconButton
            sx={{
              marginLeft: 'auto',
              position: 'absolute',
              color: Colors.white,
              right: '5px',
              top: '7px',
            }}
          >
            <Close
              sx={{ width: '13px', height: '13px', color: Colors.white }}
            />
          </IconButton>
        </>
      </Alert>
    </Stack>
  );
};
