import { ReactNode } from 'react';
import {
  CircularProgress,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';

interface SpinnerRowProps {
  height: string;
  colCount: number;
  spinnerSize: string;
}

export const TableRowWithSpinner = ({
  height,
  colCount,
  spinnerSize,
}: SpinnerRowProps) => {
  return (
    <TableRow>
      <TableCell
        sx={{ border: 'none', height }}
        align="center"
        colSpan={colCount}
      >
        <CircularProgress
          size={spinnerSize}
          sx={{ color: Colors.white, border: 'none' }}
        />
      </TableCell>
    </TableRow>
  );
};

interface EmptyStateRowProps {
  height: string;
  colCount: number;
  title: string;
}

export const TableRowEmptyState = ({
  height,
  colCount,
  title,
}: EmptyStateRowProps) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          border: 'none',
          height,
          fontSize: FontSizes.fontSize17,
          opacity: '0.2',
          color: Colors.white,
        }}
        align="center"
        colSpan={colCount}
      >
        {title}
      </TableCell>
    </TableRow>
  );
};

interface TableCellWithLinkProps {
  handleClick: () => void;
  isActive: boolean;
  itemTitle: string | number | ReactNode;
  align?: 'left' | 'center' | 'right';
  children?: ReactNode;
}

export const TableCellWithLink = ({
  handleClick,
  isActive,
  itemTitle,
  align,
  children,
}: TableCellWithLinkProps) => {
  return (
    <Typography
      onClick={handleClick}
      sx={{
        fontSize: FontSizes.fontSize13,
        color: Colors.white,
        textDecoration: isActive ? 'underline' : 'none',
        cursor: isActive ? 'pointer' : 'default',
        textAlign: align || 'left',
      }}
    >
      {itemTitle}
      {children}
    </Typography>
  );
};
