export enum Colors {
  black = '#000000',
  lightSilver = '#c3c3c3',
  white = '#fff',
  blackPearl = '#031c20',
  tiber = '#04262b',
  plantation = '#234549',
  darkDesaturatedCyan = '#365155',
  purple = '#5346F8',
  lightBlue = '#5054FF',
  blue = '#4788C8',
  green = '#00CA1E',
  darkCyan = '#597275',
  red = '#F85455',
  darkGray = '#979797',
  whiteTransparent04 = 'rgba(255, 255, 255, 0.4)',
  whiteTransparent05 = 'rgba(255, 255, 255, 0.5)',
  whiteTransparent07 = 'rgba(255, 255, 255, 0.7)',
  lightRed = '#f77a63',
  orange = '#ff9d2b',
  yellow = '#fdd60d',
  lightGreen = '#74F257',
}
