import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';

import { FontSizes } from 'constants/font-sizes';
import { Colors } from 'constants/colors';

export interface StyledButtonProps extends ButtonProps {
  color: 'error' | 'info' | 'primary';
  variant: 'text' | 'outlined' | 'contained';
  size: 'small' | 'medium' | 'large';
}

export const StyledButton = styled((props: StyledButtonProps) => (
  <Button {...props} />
))({
  textTransform: 'none',

  '&.MuiButton-sizeSmall': {
    height: '25px',
    fontSize: FontSizes.fontSize11,
  },

  '&.MuiButton-sizeMedium': {
    height: '30px',
    fontSize: FontSizes.fontSize11,
  },

  '&.MuiButton-sizeLarge': {
    height: '34px',
    fontSize: FontSizes.fontSize14,
  },

  '&.MuiButton-outlined': {
    border: `1px solid ${Colors.white}`,
    color: Colors.white,

    '& .MuiSvgIcon-root': {
      color: Colors.white,
    },

    '&.Mui-disabled': {
      borderColor: Colors.white,
      color: Colors.white,
      opacity: 0.2,

      '& .MuiSvgIcon-root': {
        color: Colors.white,
      },
    },

    '&.MuiButton-outlinedError': {
      borderColor: Colors.red,
      color: Colors.red,

      '& .MuiSvgIcon-root': {
        color: Colors.red,
      },

      '&.Mui-disabled': {
        borderColor: Colors.red,
        color: Colors.red,
        opacity: 0.2,

        '& .MuiSvgIcon-root': {
          color: Colors.red,
        },
      },
    },

    '&.MuiButton-outlinedPrimary': {
      borderColor: Colors.purple,
      color: Colors.white,

      '&.Mui-disabled': {
        borderColor: Colors.purple,
        color: Colors.white,
        opacity: 0.2,
      },
    },

    '&:hover': {
      opacity: 0.8,
    },
  },

  '&.MuiButton-contained': {
    border: `1px solid ${Colors.white}`,
    backgroundColor: Colors.white,
    color: Colors.black,

    '& .MuiSvgIcon-root': {
      color: Colors.black,
    },

    '&.Mui-disabled': {
      borderColor: Colors.white,
      backgroundColor: Colors.white,
      color: Colors.black,
      opacity: 0.2,

      '& .MuiSvgIcon-root': {
        color: Colors.black,
      },
    },

    '&.MuiButton-containedError': {
      borderColor: Colors.red,
      backgroundColor: Colors.red,
      color: Colors.white,

      '& .MuiSvgIcon-root': {
        color: Colors.white,
      },

      '&.Mui-disabled': {
        borderColor: Colors.red,
        backgroundColor: Colors.red,
        color: Colors.white,
        opacity: 0.2,

        '& .MuiSvgIcon-root': {
          color: Colors.white,
        },
      },
    },

    '&.MuiButton-containedPrimary': {
      borderColor: Colors.purple,
      backgroundColor: Colors.purple,
      color: Colors.white,

      '& .MuiSvgIcon-root': {
        color: Colors.white,
      },

      '&.Mui-disabled': {
        borderColor: Colors.purple,
        backgroundColor: Colors.purple,
        color: Colors.white,
        opacity: 0.2,

        '& .MuiSvgIcon-root': {
          color: Colors.white,
        },
      },
    },

    '&:hover': {
      opacity: 0.8,
    },
  },

  '&.MuiButton-text': {
    color: Colors.white,

    '&.Mui-disabled': {
      color: Colors.white,
      opacity: 0.2,
    },

    '&.MuiButton-textError': {
      color: Colors.red,

      '& .MuiSvgIcon-root': {
        color: Colors.red,
      },

      '&.Mui-disabled': {
        color: Colors.red,
        opacity: 0.2,

        '& .MuiSvgIcon-root': {
          color: Colors.red,
        },
      },
    },

    '&:hover': {
      opacity: 0.8,
    },
  },
});
