import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import { Colors } from 'constants/colors';
import { PDFDocumentViewer } from 'pages/connectors/components/connector-details/components/pdf-document-viewer/pdf-document-viewer';
import { connectorMappedToPDFDoc } from 'pages/connectors/components/connector-details/components/constants';
import { StyledPDFButton } from 'pages/connectors/components/connector-details/styles';
import { CustomSvgIcon } from 'components/svg-icon/svg-icon';
import { ReactComponent as CollapseIcon } from 'assets/icons/collapse.svg';
import { FontSizes } from 'constants/font-sizes';
import { ModalContainer } from 'components/modals/modal-container/modal-container';

export const PdfPreviewModal = () => {
  const dispatch = useDispatch();
  const isModalOpen = useIsPlainModalOpenSelector(
    PlainModals.PDF_PREVIEW_MODAL,
  );
  const metaData = useGetModalMetaData();

  const [pagesCount, setPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const closeModal = () =>
    dispatch(closeModalByNameAction(PlainModals.PDF_PREVIEW_MODAL));

  const onDocumentLoadSuccess = useCallback(
    (metadata: { _pdfInfo: { numPages: number } }) => {
      const pages = metadata._pdfInfo.numPages;
      setPagesCount(pages);
    },
    [setPagesCount],
  );

  const clickPreviousPage = () => {
    setCurrentPage(currentPage === 1 ? currentPage : currentPage - 1);
  };

  const clickNextPage = () => {
    setCurrentPage(currentPage === pagesCount ? currentPage : currentPage + 1);
  };

  return (
    <ModalContainer
      isOpenModal={isModalOpen}
      modalWidth="700px"
      modalHeight="calc(100% - 50px)"
    >
      <IconButton
        onClick={closeModal}
        sx={{
          position: 'absolute',
          zIndex: '10',
          right: '5px',
          top: '5px',
          color: Colors.white,
        }}
      >
        <CustomSvgIcon sx={{ fontSize: FontSizes.fontSize17 }}>
          <CollapseIcon />
        </CustomSvgIcon>
      </IconButton>
      <PDFDocumentViewer
        connectorId={
          metaData.connector_id as keyof typeof connectorMappedToPDFDoc
        }
        onDocumentLoadSuccess={onDocumentLoadSuccess}
        currentPage={currentPage}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginTop: 'auto',
          padding: '0 40px',
        }}
      >
        <StyledPDFButton
          disabled={currentPage === 1}
          startIcon={<ArrowBack sx={{ width: '9px', height: '9px' }} />}
          onClick={clickPreviousPage}
        >
          Prev
        </StyledPDFButton>
        <StyledPDFButton
          disabled={pagesCount === currentPage}
          endIcon={<ArrowForward sx={{ width: '9px', height: '9px' }} />}
          onClick={clickNextPage}
        >
          Next
        </StyledPDFButton>
      </Box>
    </ModalContainer>
  );
};
