import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useKeycloak } from '@react-keycloak/web';

import { Colors } from 'constants/colors';
import logo from 'assets/images/logo.png';
import { ReactComponent as ListIcon } from 'assets/icons/list-icon.svg';
import {
  Drawer,
  DrawerHeader,
  ExpandArrowContainer,
  Logo,
  LogoContainer,
  MenuItemWrapper,
  NavItemText,
  NavChildItemText,
  VersionInfo,
  CustomMenuIcon,
  StyledLink,
} from './styles';
import { CustomSvgIcon } from '../svg-icon/svg-icon';
import { navItems } from './constants';
import { getPath } from 'utils/nav/nav-utils';
import { paths } from 'constants/routes';
import { FontSizes } from 'constants/font-sizes';
import { useDispatch } from 'react-redux';
import {
  FiltersTableName,
  setFiltersForTable,
} from 'store/modules/filters/filters.reducer';

export const Navigation = () => {
  const { pathname } = useLocation();
  const currentPath = getPath(pathname);
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const [isChildrenOpen, setIsChildrenOpen] = useState<Record<string, boolean>>(
    {
      actionCenter: false,
      settings: false,
      resources: false,
    },
  );
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(true);

  const checkRoutePath = (path: string) => {
    if (path === 'entityGraph') {
      dispatch(
        setFiltersForTable({
          tableName: FiltersTableName.ENTITY_GRAPH,
          filters: {},
        }),
      );
    }
  };

  return (
    <Drawer
      variant="permanent"
      open={isDrawerExpanded}
      sx={{ backgroundColor: Colors.blackPearl }}
    >
      <DrawerHeader>
        <LogoContainer
          textOpacity={isDrawerExpanded ? 1 : 0}
          onClick={() => setIsDrawerExpanded(true)}
        >
          <Logo src={logo} />
          <span>IdentityScience</span>
        </LogoContainer>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setIsDrawerExpanded(false)}
          edge="start"
          sx={{ color: 'white', padding: '8px 0 8px', marginLeft: '15px' }}
        >
          <CustomSvgIcon sx={{ fontSize: FontSizes.fontSize12 }}>
            <ListIcon />
          </CustomSvgIcon>
        </IconButton>
        {isDrawerExpanded && <VersionInfo>Version 1.0.0</VersionInfo>}
      </DrawerHeader>
      <MenuList
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 150px)',
        }}
      >
        {navItems.map((item) => {
          const isActiveItem = item.activePaths
            ? item.activePaths.includes(currentPath)
            : currentPath === paths[item.path];
          return (
            <MenuItemWrapper isBottomItem={item.bottomItem} key={item.id}>
              <StyledLink
                to={paths[item.path]}
                onClick={() => checkRoutePath(item.path)}
              >
                <CustomMenuIcon
                  onClick={() => {
                    if (item.id === 'signout') {
                      keycloak.logout();
                      return;
                    }
                    setIsChildrenOpen((prevState) => ({
                      ...prevState,
                      [item.id]: !prevState[item.id],
                    }));
                  }}
                  isActive={isActiveItem}
                  sx={{
                    height: '31px',
                    backgroundColor: isActiveItem
                      ? 'rgba(0, 0, 0, 0.31)'
                      : 'transparent',
                  }}
                >
                  <ListItemIcon sx={{ paddingLeft: '8px' }}>
                    <CustomSvgIcon
                      iconColor={isActiveItem ? Colors.purple : Colors.white}
                    >
                      {item?.icon()}
                    </CustomSvgIcon>
                  </ListItemIcon>
                  <NavItemText opacity={isDrawerExpanded ? 1 : 0}>
                    {item.title}
                  </NavItemText>
                  {item.children && isDrawerExpanded && (
                    <ExpandArrowContainer>
                      {isChildrenOpen[item.id] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ExpandArrowContainer>
                  )}
                </CustomMenuIcon>
              </StyledLink>
              {item.children && (
                <Collapse
                  in={isChildrenOpen[item.id] && isDrawerExpanded}
                  timeout="auto"
                  unmountOnExit
                >
                  {item.children.map(({ id, path, title }) => {
                    const isActiveChildItem = currentPath === paths[path];
                    return (
                      <StyledLink to={paths[path]} key={id}>
                        <CustomMenuIcon
                          isActive={isActiveChildItem}
                          sx={{ pl: 5, height: '31px', marginTop: '10px' }}
                        >
                          <NavChildItemText isActive={isActiveChildItem}>
                            {title}
                          </NavChildItemText>
                        </CustomMenuIcon>
                      </StyledLink>
                    );
                  })}
                </Collapse>
              )}
            </MenuItemWrapper>
          );
        })}
      </MenuList>
    </Drawer>
  );
};
