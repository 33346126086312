import React, { FC } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { connectorMappedToPDFDoc } from '../constants';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
  size: { width: '470px', height: '580px' },
};

interface Props {
  connectorId: keyof typeof connectorMappedToPDFDoc;
  onDocumentLoadSuccess: (metadata: { _pdfInfo: { numPages: number } }) => void;
  currentPage: number;
}

const Viewer: FC<Props> = ({
  connectorId,
  onDocumentLoadSuccess,
  currentPage,
}) => {
  return (
    <Document
      file={{
        url: connectorMappedToPDFDoc[connectorId],
      }}
      onLoadSuccess={onDocumentLoadSuccess}
      options={options}
    >
      <Page pageNumber={currentPage} />
    </Document>
  );
};

export const PDFDocumentViewer = React.memo(Viewer);
