import { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'react-toastify/dist/ReactToastify.css';
import { initiateStore } from 'store/store';
import { generateBaseApi } from 'store/apis/base';
import { ApiProvider } from 'components/providers/api-provider/api-provider';
import { theme } from 'components/theme/theme';
import { generateRoutingList } from 'constants/routes';

import { Auth } from './components/auth/auth';
import { RoutesWrapper } from 'utils/route-wrapper';
import { MainContent } from 'main-content';
import './App.css';

export function App() {
  const { keycloak, initialized } = useKeycloak();
  useEffect(() => {
    console.log(keycloak.authenticated);
    console.log(initialized);
    console.log(keycloak.tokenParsed);
  }, [keycloak, initialized]);

  dayjs.extend(localizedFormat);

  const getToken = async () => keycloak.token || '';
  const baseApi = generateBaseApi(getToken);
  const store = initiateStore(baseApi);

  const routes = generateRoutingList();

  const renderMainContent = () => {
    if (keycloak.authenticated) {
      return (
        <Suspense fallback={null}>
          <Routes>
            {routes.map((route) => {
              if (route.isRender) {
                return (
                  <Route
                    key={route.id}
                    path={route.path}
                    element={<RoutesWrapper>{route.element}</RoutesWrapper>}
                  />
                );
              }
              return null;
            })}
          </Routes>
        </Suspense>
      );
    }
    return null;
  };

  return (
    <ApiProvider baseApi={baseApi}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Auth />
          <MainContent renderMainContent={renderMainContent} />
          <Box data-testid="main-wrapper"></Box>
        </ThemeProvider>
      </Provider>
    </ApiProvider>
  );
}
