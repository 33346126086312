import { ReactNode } from 'react';

import { ReactComponent as HealthOverviewIcon } from 'assets/icons/health-overview.svg';
// import { ReactComponent as RiskExplorerIcon } from 'assets/icons/risk-explorer.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as ResourcesIcon } from 'assets/icons/resources.svg';
// import { ReactComponent as FeedbackSupportIcon } from 'assets/icons/feedback-support.svg';
// import { ReactComponent as DocumentationIcon } from 'assets/icons/documentation.svg';
import { ReactComponent as OpenSourceDependenciesIcon } from 'assets/icons/open-source-dependencies.svg';
import { ReactComponent as ReportingIcon } from 'assets/icons/reporting.svg';
import { ReactComponent as PolicyBuilderIcon } from 'assets/icons/policies.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { ReactComponent as SignOutIcon } from 'assets/icons/sign-out.svg';
import { ReactComponent as ComplianceIcon } from 'assets/icons/compliance-icon.svg';
import { paths } from 'constants/routes';

export interface NavItemProps {
  id: string;
  path: keyof typeof paths;
  title: string;
  activePaths?: string[];
  children?: ChildNavItemProps[];
  icon: () => ReactNode;
  bottomItem?: boolean;
}

export interface ChildNavItemProps {
  id: string;
  path: keyof typeof paths;
  title: string;
}

export const navItems: NavItemProps[] = [
  {
    id: 'dashboard',
    path: 'dashboard',
    title: 'Health Overview',
    icon: () => <HealthOverviewIcon />,
  },

  {
    id: 'policies',
    path: 'policies',
    title: 'Policies',
    icon: () => <PolicyBuilderIcon />,
  },
  {
    id: 'reporting',
    path: 'alerts',
    title: 'Reporting',
    activePaths: [paths.alerts, paths.reporting],
    icon: () => <ReportingIcon />,
    children: [
      {
        id: 'alerts',
        path: 'alerts',
        title: 'Alerts',
      },
      {
        id: 'reporting',
        path: 'reporting',
        title: 'Report History',
      },
    ],
  },
  {
    id: 'complianceOverview',
    path: 'complianceOverview',
    title: 'Compliance',
    icon: () => <ComplianceIcon />,
  },
  {
    id: 'resources',
    title: 'Resources',
    path: 'identities',
    activePaths: [
      paths.identities,
      paths.assets,
      paths.teams,
      paths.entityGraph,
    ],
    icon: () => <ResourcesIcon />,
    children: [
      {
        id: 'identities',
        path: 'identities',
        title: 'Identities',
      },
      {
        id: 'assets',
        path: 'assets',
        title: 'Assets',
      },
      {
        id: 'teams',
        path: 'teams',
        title: 'Teams',
      },
      {
        id: 'entityGraph',
        path: 'entityGraph',
        title: 'Entity Graph',
      },
    ],
  },
  {
    id: 'opensourceDependencies',
    path: 'opensourceDependencies',
    title: 'Open Source',
    icon: () => <OpenSourceDependenciesIcon />,
  },
  {
    id: 'secrets',
    path: 'secrets',
    title: 'Secrets',
    icon: () => <KeyIcon />,
  },
  {
    id: 'settings',
    path: 'userManagement',
    title: 'Settings',
    activePaths: [
      paths.userManagement,
      paths.connectors,
      paths.tenantSettingsWatchHide,
    ],
    icon: () => <SettingsIcon />,
    children: [
      {
        id: 'userManagement',
        path: 'userManagement',
        title: 'User Management',
      },
      {
        id: 'connectors',
        path: 'connectors',
        title: 'Connectors',
      },
      {
        id: 'tenant-settings',
        path: 'tenantSettings',
        title: 'Tenant Settings',
      },
      {
        id: 'tenantSettingsWatchHide',
        path: 'tenantSettingsWatchHide',
        title: 'Watch/Hide',
      },
      {
        id: 'recommendationsStatus',
        path: 'recommendationsStatus',
        title: 'Recommendations Status',
      },
    ],
  },
  // {
  //   bottomItem: true,
  //   id: 'feedbackSupport',
  //   title: 'Feedback/Support',
  //   icon: () => <FeedbackSupportIcon />,
  // },
  // {
  //   id: 'documentation',
  //   title: 'Documentation',
  //   icon: () => <DocumentationIcon />,
  // },
  {
    bottomItem: true,
    id: 'signout',
    title: 'Sign Out',
    path: 'signout',
    icon: () => <SignOutIcon />,
  },
];
