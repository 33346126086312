import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { NewUserFormInputs } from './types';
import { UserFields } from './constants';
import { emailPattern } from 'utils/general/reg-exp-patterns';
import { ToastMessage } from 'components/toast/toast-message';
import { useIsPlainModalOpenSelector } from 'store/modules/modals/modals.selector';
import {
  StyledFormControl,
  StyledFormLabel,
} from 'pages/connectors/components/connector-details/components/inputs-schemas/styles';
import { StyledInput } from 'components/table/styles';
import { FontSizes } from 'constants/font-sizes';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import {
  StyledSelect,
  StyledOptionItem,
} from 'components/inputs/select/select';

const optionsList = [
  {
    id: 'user',
    name: 'User',
  },
  {
    id: 'admin',
    name: 'Administrator',
  },
];

export const InviteUserModal = () => {
  const dispatch = useDispatch();
  const isInviteUserOpenModal = useIsPlainModalOpenSelector(
    PlainModals.INVITE_USER_MODAL,
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<NewUserFormInputs>({
    defaultValues: {
      [UserFields.name]: '',
      [UserFields.email]: '',
      [UserFields.role]: optionsList[0].id,
    },
  });

  const apiContext = useContext(ApiContext);
  const [inviteUser] = apiContext.baseApi.useInviteUserMutation();

  const handleCloseModal = () =>
    dispatch(closeModalByNameAction(PlainModals.INVITE_USER_MODAL));

  const handleConfirm = () => {
    handleSubmit(async (data) => {
      try {
        const { name, email, role } = data;
        await inviteUser({
          name,
          email,
          role,
        });
        handleCloseModal();
        toast(
          <ToastMessage
            type="success"
            title="Your request has been successfully processed"
          />,
        );
      } catch (error) {
        toast(
          <ToastMessage type="error" title="Your request was unsuccessful" />,
        );
      }
    })();
  };

  const onRoleSelect = (id: string) => {
    setValue('role', id, { shouldValidate: true });
  };

  const [userRole] = watch([UserFields.role]);

  return (
    <ModalContainer
      handleCloseModal={handleCloseModal}
      handleConfirm={handleConfirm}
      isOpenModal={isInviteUserOpenModal}
      title="Invite a User"
      subTitle="Email address and role selection are required."
      closeModalTitle="Cancel"
      confirmModalTitle="Send Invitation"
      modalHeight="350px"
      modalWidth="500px"
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
          paddingTop: '10px',
          rowGap: '15px',
        }}
      >
        <StyledFormControl sx={{ width: '100%' }}>
          <StyledFormLabel sx={{ fontSize: FontSizes.fontSize10 }}>
            Name
          </StyledFormLabel>
          <StyledInput
            autoComplete="off"
            placeholder="Enter Name"
            {...register(UserFields.name, {
              minLength: 1,
              maxLength: 30,
              required: true,
            })}
            error={errors.hasOwnProperty(UserFields.name)}
          />
        </StyledFormControl>
        <StyledFormControl sx={{ width: '100%' }}>
          <StyledFormLabel sx={{ fontSize: FontSizes.fontSize10 }}>
            Email Address
          </StyledFormLabel>
          <StyledInput
            autoComplete="off"
            placeholder="Enter Email Address"
            {...register(UserFields.email, {
              required: true,
              pattern: emailPattern,
            })}
            error={errors.hasOwnProperty(UserFields.email)}
          />
        </StyledFormControl>
        <StyledFormControl sx={{ width: '100%' }}>
          <StyledFormLabel sx={{ fontSize: FontSizes.fontSize10 }}>
            Role
          </StyledFormLabel>
          <StyledSelect
            variant="standard"
            color="secondary"
            size="medium"
            onChange={(event) => onRoleSelect(event.target.value as string)}
            value={userRole}
            error={errors.hasOwnProperty(UserFields.role)}
          >
            <StyledOptionItem value="user">User</StyledOptionItem>
            <StyledOptionItem value="admin">Administrator</StyledOptionItem>
          </StyledSelect>
        </StyledFormControl>
      </Box>
    </ModalContainer>
  );
};
