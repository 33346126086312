import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';

import {
  DatesContainer,
  DatesPopupContainer,
  SelectDatesContainer,
  ButtonsContainer,
  DateValue,
  DateRangeInputContainer,
  StyledDatePicker,
} from './styles';
import { FontSizes } from 'constants/font-sizes';
import { StyledButton } from '../../button/button';
import { Colors } from 'constants/colors';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  onSubmitHandler: () => void;
}

export const DateRangeInput = ({
  startDate,
  endDate,
  minDate,
  maxDate,
  setEndDate,
  setStartDate,
  onSubmitHandler,
}: Props) => {
  const [isToggle, setIsToggle] = useState(false);

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <DateRangeInputContainer>
      <SelectDatesContainer
        sx={{
          width: minDate ? '100%' : '130px',
          height: minDate ? '32px' : '25px',
        }}
        onClick={() => setIsToggle(true)}
      >
        <Typography
          sx={{
            fontSize: FontSizes.fontSize11,
            color: Colors.whiteTransparent07,
          }}
        >
          {startDate && endDate
            ? `${dayjs(startDate).format('MMM DD')} - ${dayjs(endDate).format(
                'MMM DD',
              )}`
            : 'Select dates'}
        </Typography>
        <UnfoldMore
          sx={{
            width: '14px',
            height: '14px',
            color: Colors.whiteTransparent07,
            marginLeft: 'auto',
          }}
        />
      </SelectDatesContainer>
      {isToggle && (
        <DatesPopupContainer>
          <DatesContainer>
            <DateValue>
              <Typography
                sx={{
                  fontSize: FontSizes.fontSize11,
                  color: Colors.whiteTransparent07,
                }}
              >
                {startDate ? dayjs(startDate).format('MMMM DD') : 'Start date'}
              </Typography>
            </DateValue>
            -
            <DateValue>
              <Typography
                sx={{
                  fontSize: FontSizes.fontSize11,
                  color: Colors.whiteTransparent07,
                }}
              >
                {endDate ? dayjs(endDate).format('MMMM DD') : 'End date'}
              </Typography>
            </DateValue>
          </DatesContainer>
          <StyledDatePicker
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            maxDate={maxDate || new Date()}
            minDate={minDate}
            selectsRange
            inline
          />
          <ButtonsContainer>
            <StyledButton
              variant="text"
              color="info"
              size="small"
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
                setIsToggle(false);
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                onSubmitHandler();
                setIsToggle(false);
              }}
            >
              Apply
            </StyledButton>
          </ButtonsContainer>
        </DatesPopupContainer>
      )}
    </DateRangeInputContainer>
  );
};
