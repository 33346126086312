import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import dayjs from 'dayjs';
import {
  Box,
  Typography,
  Link,
  styled,
  Tab,
  Tabs,
  TableRow,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  Chip,
} from '@mui/material';
import { Colors } from 'constants/colors';
import { ReactComponent as HighSeverityIcon } from 'assets/icons/severity-high.svg';
import { ReactComponent as MediumSeverityIcon } from 'assets/icons/severity-medium.svg';
import { ReactComponent as LowSeverityIcon } from 'assets/icons/severity-low.svg';
import { ReactComponent as CriticalSeverityIcon } from 'assets/icons/severity-critical.svg';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';

import {
  StyledTableBodyCell,
  StyledTableHeaderCell,
} from 'components/table/styles';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
// import { TableCellWithLink } from 'components/table/table-components/table-components';
// import { paths } from 'constants/routes';
import dayjs from 'dayjs';
import { CustomSvgIcon } from 'components/svg-icon/svg-icon';
import { ReactComponent as GitHubIcon } from 'assets/icons/github.svg';

const contributorProfileChecklist = [
  { key: 'email', title: 'Email' },
  { key: 'bio', title: 'Bio' },
  { key: 'blog', title: 'Blog' },
  { key: 'company', title: 'Company' },
  { key: 'location', title: 'Location' },
  { key: 'twitter_username', title: 'Twitter' },
];

function sortBySeverity(
  a: {
    packageName: string;
    severity: 'CRITICAL' | 'HIGH' | 'MODERATE' | 'LOW';
  },
  b: {
    packageName: string;
    severity: 'CRITICAL' | 'HIGH' | 'MODERATE' | 'LOW';
  },
) {
  const severityOrder: Record<
    'CRITICAL' | 'HIGH' | 'MODERATE' | 'LOW',
    number
  > = { CRITICAL: 0, HIGH: 1, MODERATE: 2, LOW: 3 };

  debugger;

  const severityA: any = severityOrder[a.severity];
  const severityB: any = severityOrder[b.severity];

  // Compare severity first
  if (severityA < severityB) {
    return -1;
  } else if (severityA > severityB) {
    return 1;
  }

  // If severity is the same, compare by name
  return a.packageName.localeCompare(b.packageName);
}

const StyledTabs = styled(Tabs)`
  padding: 10px;
`;

const StyledTab = styled(Tab)`
  && {
    color: #fff;
    text-transform: none;
    font-size: 16px;

    &.Mui-selected {
      color: #2196f3;
    }
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ContributorsReputationModal = () => {
  const dispatch = useDispatch();
  const metaData = useGetModalMetaData();
  const [value, setValue] = useState(0);

  //@ts-ignore
  const transparencyScore: any = metaData?.transparencyInfo;

  console.log('TRANSPARENCY INFO', transparencyScore);

  const transformPackagesWithAdvisories = (advisories: any[]) => {
    const copyAdvisories = [...advisories];
    return copyAdvisories.sort(sortBySeverity);
  };

  const renderSeverityCell = (item: any) => {
    let chipColor = '';
    let chipBackground = '';
    let chipIcon: JSX.Element;
    switch (item.severity?.toLocaleUpperCase()) {
      case 'CRITICAL':
        chipColor = '#F85455';
        chipBackground = 'rgba(248, 84, 85, 0.2);';
        chipIcon = (
          <CriticalSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      case 'HIGH':
        chipColor = '#F77A63';
        chipBackground = 'rgba(247, 122, 99, 0.2)';
        chipIcon = (
          <HighSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      case 'MODERATE':
        chipColor = '#FF9D2B';
        chipBackground = 'rgba(255, 157, 43, 0.2)';
        chipIcon = (
          <MediumSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      case 'LOW':
        chipColor = '#FDD60D';
        chipBackground = 'rgba(253, 214, 13, 0.2)';
        chipIcon = (
          <LowSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      default:
        chipColor = '#FDD60D';
        chipBackground = 'rgba(253, 214, 13, 0.2)';
        chipIcon = (
          <LowSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
    }
    return (
      <Chip
        size="small"
        sx={{
          backgroundColor: chipBackground,
          color: chipColor,
          border: `1.8px solid ${chipBackground}`,
          px: 1,
        }}
        icon={chipIcon}
        label={item.severity?.toLocaleUpperCase()}
        variant="outlined"
      />
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const isOpenModal = useIsPlainModalOpenSelector(
    PlainModals.CONTRIBUTORS_REPUTATION_MODAL,
  );

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.CONTRIBUTORS_REPUTATION_MODAL));
  };

  return (
    <ModalContainer
      isOpenModal={isOpenModal}
      title={''}
      alignItems={'left'}
      handleCloseModal={handleCloseModal}
      modalWidth="700px"
      modalHeight="700px"
      backgroundColor="#04262b"
    >
      <Box
        sx={{
          background: 'rgba(3, 28, 32, 0.5) !important',
          border: '1px solid rgba(255, 255, 255, 0.3)',
          borderRadius: '4px',
          padding: '28px 35px',
          marginTop: '10px',
          marginBottom: '20px',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: Colors.white,
            fontFamily: fontSecondary,
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            textTransform: 'capitalize',
          }}
        >
          <CustomSvgIcon sx={{ fontSize: FontSizes.fontSize13 }}>
            <GitHubIcon />
          </CustomSvgIcon>
          {transparencyScore.login}
          <Typography
            sx={{
              fontSize: FontSizes.fontSize13,
              fontWeight: 500,
              letterSpacing: '0.02857em',
              marginLeft: '10px',
            }}
          >
            <Link
              sx={{
                fontWeight: 500,
                color: Colors.white,
                textDecoration: 'underline',
              }}
              rel="noopener"
              href={`https://github.com/${transparencyScore?.login}`}
              target="_blank"
              underline="none"
            >
              View Profile
            </Link>
          </Typography>
        </Typography>
        <Box sx={{ display: 'flex', gap: '80px', paddingLeft: '25px', mt: 3 }}>
          <Box>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.85)',
                fontSize: FontSizes.fontSize12,
                mb: 1,
              }}
            >
              Joined On
            </Typography>
            <Typography
              sx={{
                fontSize: FontSizes.fontSize12,
              }}
            >
              {transparencyScore?.created_at
                ? dayjs(transparencyScore?.created_at).format('L')
                : '-'}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.85)',
                fontSize: FontSizes.fontSize12,
                mb: 1,
              }}
            >
              Followers
            </Typography>
            <Typography
              sx={{
                fontSize: FontSizes.fontSize12,
              }}
            >
              {transparencyScore?.num_followers || ''}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.85)',
                fontSize: FontSizes.fontSize12,
                mb: 1,
              }}
            >
              Total Packages Contributed To
            </Typography>
            <Typography
              sx={{
                fontSize: FontSizes.fontSize12,
              }}
            >
              {transparencyScore?.unique_package_count || '1'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <StyledTabs
          value={value}
          onChange={handleChange}
          sx={{ fontFamily: fontSecondary }}
          aria-label="basic tabs example"
        >
          <StyledTab
            sx={{
              fontFamily: fontSecondary,
            }}
            label="Reputation"
            {...a11yProps(0)}
          />
          {transparencyScore.vulnerable_packages.length > 0 && (
            <StyledTab
              sx={{
                fontFamily: fontSecondary,
              }}
              label={`Vulnerabilities (${transparencyScore?.vulnerable_packages.length})`}
              {...a11yProps(1)}
            />
          )}
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box>
          <Typography sx={{ fontSize: '16px', fontWeight: 600 }} mb={1}>
            Reputation Checklist
          </Typography>
          {contributorProfileChecklist.map(
            (item: { title: string; key: string }, index: number) => (
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="start"
                key={item.key}
                sx={{
                  cursor:
                    transparencyScore &&
                    transparencyScore[item.key] !== null &&
                    transparencyScore[item.key]?.length > 0
                      ? 'default'
                      : 'pointer',
                  py: 1,
                  color:
                    transparencyScore &&
                    !transparencyScore[item.key] !== null &&
                    transparencyScore[item.key]?.length > 0
                      ? Colors.white
                      : Colors.darkDesaturatedCyan,
                }}
              >
                {transparencyScore &&
                transparencyScore[item.key] !== null &&
                transparencyScore[item.key]?.length > 0 ? (
                  <CheckCircleOutlineIcon
                    sx={{ fontSize: '18px', mt: '1.5px', color: '#00CA1E' }}
                  />
                ) : (
                  <CheckCircleOutlineIcon
                    sx={{
                      fontSize: '18px',
                      mt: '1.5px',
                      color: Colors.darkDesaturatedCyan,
                    }}
                  />
                )}
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                  ml={1}
                >
                  <Typography
                    variant="body2"
                    fontWeight={550}
                    sx={{
                      textDecoration: 'none',
                      color:
                        transparencyScore &&
                        transparencyScore[item.key] !== null &&
                        transparencyScore[item.key]?.length > 0
                          ? Colors.white
                          : Colors.darkDesaturatedCyan,
                    }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              </Box>
            ),
          )}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {transparencyScore?.vulnerable_packages &&
          transparencyScore?.vulnerable_packages.length > 0 && (
            <TableContainer sx={{ marginTop: '25px', maxHeight: '300px' }}>
              <Table
                stickyHeader
                sx={{ minWidth: '550px', backgroundColor: 'transparent' }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableHeaderCell align="left">
                      Package Name
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell align="left">
                      Severity
                    </StyledTableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transformPackagesWithAdvisories(
                    transparencyScore?.vulnerable_packages,
                  ).map((item: any, index: number) => (
                    <TableRow key={index}>
                      <StyledTableBodyCell>
                        {item.packageName}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        {renderSeverityCell(item)}
                      </StyledTableBodyCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
      </TabPanel>
    </ModalContainer>
  );
};
