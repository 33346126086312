import { InputsSchemas } from 'pages/connectors/types';
import Plotly from 'plotly.js';

export interface GeneralResponseItem {
  [key: string]: number | boolean;
}

export interface ChartData {
  isEmptyData: boolean;
  chartData: Plotly.Data[] | Plotly.PieData[] | any;
}

export interface ResponseObject {
  total: number;
  items_page: number;
  page: number;
  data: any[];
}

export interface Owner {
  login: string;
  user_id: string;
}

export interface IdentitiesUserItemRes {
  external: boolean;
  flagged: boolean;
  internal: boolean;
  last_activity: string;
  login: string;
  status_active: boolean;
  team_member: boolean;
  unknown: boolean;
  user_id: string;
  display_name: string;
  repos_count: number;
  count_admin_permissions: number;
  account_type: string;
  access_count: number;
  outliers_count: number;
  permissions_count: number;
  hidden: boolean;
  overprivilege_score: number;
  composite_score?: number;
  watched: boolean;
  highest_severity: string;
  highest_severity_count: number;
  highest_severity_frequency: number;
  y_axis: number;
}

export interface AssetsRepositoriesItemRes {
  archived: boolean;
  critical_repo: boolean;
  disabled: boolean;
  first_commit: string;
  inactive: boolean;
  last_activity: string;
  name: string;
  owners_list?: Owner[];
  repo_slug: string;
  watched: boolean;
}

export interface IdentitiesUserItem extends IdentitiesUserItemRes {
  identity_status: string;
}

export interface IdentitiesUserObjRes extends ResponseObject {
  data: IdentitiesUserItemRes[];
}

export interface IdentitiesUserObj extends ResponseObject {
  data: IdentitiesUserItem[];
}

export enum IdentitiesAccountType {
  total = 'total',
  service = 'service',
  human = 'user',
}

export enum IdentitiesAccountStatus {
  active = 'active',
  inactive = 'inactive',
}

export interface Owner {
  login: string;
  user_id: string;
}

export interface AssetsRepositoriesItemRes {
  archived: boolean;
  highest_severity: string;
  critical_repo: boolean;
  disabled: boolean;
  first_commit: string;
  inactive: boolean;
  last_activity: string;
  name: string;
  owners_list?: Owner[];
  repo_slug: string;
  hidden: boolean;
  org: string;
  is_production: boolean;
  visibility: string;
}

export interface AssetsRepositoriesObjsRes extends ResponseObject {
  data: AssetsRepositoriesItemRes[];
}

export interface AssetsRepositoriesItem extends AssetsRepositoriesItemRes {
  watched: boolean;
  owners: string;
  status: string;
}

export interface AssetsRepositoriesObj extends ResponseObject {
  data: AssetsRepositoriesItem[];
}

export interface BranchProtectionItemRes {
  protected?: boolean;
  branch_name: string;
  deployment: string;
  critical: boolean;
  environment: any;
  forking: boolean;
  protected_unprotected: boolean;
  repo_name: string;
  repo_slug: string;
  state: string;
  workflow: boolean;
}

export interface BranchProtectionObjRes extends ResponseObject {
  data: BranchProtectionItemRes[];
}

export interface Member {
  active: boolean;
  login: string;
  name: string | null;
}

export interface TeamsItemRes {
  hidden: boolean;
  watched: boolean;
  description: string;
  last_activity: string;
  org: string;
  name: string;
  num_inactive: number;
  num_total: number;
  owner: string;
  private: boolean;
  team: string;
  members: Member[];
  inactive_members: Member[];
}

export interface TeamsObjRes extends ResponseObject {
  data: TeamsItemRes[];
}

export interface TeamDetailsRes {
  name: string;
  status: boolean;
  org: string;
  total_repos: number;
  repos_admin: number;
  repos_maintain: number;
  repos_write: number;
  repos_read: number;
  repos_triage: number;
  identities: number;
  url: string;
  hidden: boolean | null;
  watched: boolean | null;
}

export interface TeamAssetItemRes {
  name: string;
  repo_slug: string;
  critical: boolean;
  permission: string;
  last_activity: string;
  days_inactive: string;
}

export interface TeamMemberItemRes {
  id: string;
  name: string;
  user_id: string;
  login: string;
  active: boolean;
  last_activity_date: string;
  external: boolean;
  account_type: string;
}

export interface DetectionRes extends ResponseObject {
  data: {
    timestamp: string;
    status: string;
    detection: string;
  }[];
}

export interface UsersSettingsItemRes {
  mfa?: boolean;
  blocked?: boolean;
  created_at: string;
  email: string;
  email_verified: boolean;
  identities: {
    user_id: string;
    provider: string;
    connection: string;
    isSocial: boolean;
  }[];
  name: string;
  nickname: string;
  picture: string;
  updated_at: string;
  user_id: string;
  last_login: string;
  last_ip: string;
  logins_count: number;
}

export interface IdentitiesUserAccessRes {
  critical_repo: boolean;
  last_activity: string;
  org: string;
  name: string;
  repo_slug: string;
  teams: { server: string }[];
}

export interface IdentitiesUserAccessObjRes extends ResponseObject {
  data: IdentitiesUserAccessRes[];
}

export interface IdentitiesUserDetails {
  external: boolean;
  internal: boolean;
  last_activity: string;
  login: string;
  not_seen_since: number;
  display_name: string;
  idm_deactivated: boolean;
  idm_source: string;
  status_active: boolean;
  team_member: boolean;
  scm_deactivated: boolean;
  unknown: boolean;
  user_id: string;
  account_type: string;
  hidden: boolean;
  author_email: string[];
  composite_risk_score: {
    identity: string;
    risky_behavior: number;
    risky_configs: number;
    total_score: number;
    total_score_normalized: number;
    risky_behavior_normalized: number;
    risky_configs_normalized: number;
  };
  overprivilege_score: {
    login: string;
    count: number;
    role_name_admin: number;
    role_name_maintain: number;
    role_name_write: number;
    role_name_read: number;
    count_unused: number;
    role_name_admin_unused: number;
    role_name_maintain_unused: number;
    role_name_write_unused: number;
    role_name_read_unused: number;
    permissions_score: number;
    count_unused_scaled: number;
    overprivilege_score: number;
  };
  watched: boolean | null;
  url: string;
  highest_severity: string;
  multi_tool_status: {
    last_activity_date: Date;
    source: string;
    status: string;
    email: string[];
  }[];
}

export interface IdentitiesUserActivityRes {
  repository: string;
  timestamp: string;
  type: string;
  geo_location: string;
  user_ip: string;
}

export interface IdentitiesUserActivityObjRes extends ResponseObject {
  data: IdentitiesUserActivityRes[];
}

export interface IdentitiesUserPipelineActivityRes {
  branch: string;
  commit_sha: string;
  created: string;
  duration: number;
  number: number;
  repository: OpenSourceRepositoryItem;
  status: string;
  name: string;
  url: string;
}

export interface IdentitiesUserPipelineActivityObjRes extends ResponseObject {
  data: IdentitiesUserPipelineActivityRes[];
}

export interface RepositoriesContributorRes {
  _id: string;
  login: string;
  user_id: string;
  inferred_owner: boolean;
  display_name: string;
  last_action: number;
  all_permissions: Record<string, string>[];
}

export interface RepositoriesContributorObjRes extends ResponseObject {
  data: RepositoriesContributorRes[];
}

export interface AssetsDetailsPipelineRes {
  id: number;
  name: string;
  user_id: string;
  repository: string;
  state: string;
  url: string;
  number: number;
  commit_sha: string;
  created: string;
}

export interface AssetsDetailsActivityRes {
  type: string;
  repository: string;
}

export interface AssetsPipelineRunsDetailsRes {
  number_of_runs: number;
  pass_or_fail: string;
  workflow_id: number;
}

export interface AssetsDetailsBranchRes {
  branch_name: string;
  branch_slug: string;
  critical: boolean;
  environment: any;
  forking: string;
  protected: boolean;
  repo_name: string;
  repo_slug: string;
  state: string;
  workflow: boolean;
}

export interface AssetsDetailsBranchesObjRes extends ResponseObject {
  data: AssetsDetailsBranchRes[];
}

export interface ExternalPackagesRepository {
  language: string;
  obscurity_risk: number;
  risk_rating: number;
  risk_reason: string;
  vulnerability_history_risk: number;
  risk_category: string;
}

export interface ExternalPackagesAdvisoryItem {
  introduced: string;
  last_affected: string;
  package: string;
  package_manager: string;
  published: string;
  severity: 'CRITICAL' | 'HIGH' | 'MODERATE' | 'LOW';
  summary: string;
}

export interface OpenSourceRepositoryItem {
  import_at: string;
  name: string;
  repo_slug: string;
  isTopLevel: boolean;
  isAffected: boolean;
  parents: any[];
  instances: number;
  version: string;
  importer: string;
  severity: string;
}

export interface OpenSourceDependenciesItemRes {
  total_score: any;
  name: string;
  package: string;
  license: string;
  readme: string;
  repo_count: number;
  advisories: any[];
  healthRating: number;
  repository_url: string;
  risk_category: string;
  package_manager: string;
  security_safety_record: number;
  identity_reputation: number;
  popularity: number;
  package_ur: string;
  last_release: string;
  subscores: Record<string, Record<string, number>>;
  repo_details: ExternalPackagesRepository;
  repositories: OpenSourceRepositoryItem[];
  advisory: ExternalPackagesAdvisoryItem[];
  community: string | null;
  top_identities: {
    username: string;
    account_type: string;
    role: string;
    data: {
      num_contributions: number;
    };
  };
}

export interface OpenSourceDependenciesObjRes extends ResponseObject {
  data: OpenSourceDependenciesItemRes[];
}

export interface ExternalPackagesObjRes extends ResponseObject {
  data: ExternalPackagesItemRes[];
}

export interface AssetsDetailsObjRes {
  archived: boolean;
  critical_repo: boolean;
  org: string;
  disabled: boolean;
  first_commit: string;
  inactive: boolean;
  last_activity: string;
  name: string;
  owners_list: Owner[];
  repo_slug: string;
  hidden: boolean;
  highest_severity: string;
  watched: boolean | null;
  url: string;
}

export interface ExternalPackagesRepository {
  language: string;
  obscurity_risk: number;
  risk_rating: number;
  risk_reason: string;
  vulnerability_history_risk: number;
  risk_category: string;
}

export interface ExternalPackagesAdvisoryItem {
  summary: string;
  details: string;
  affected: {
    package: {
      ecosystem: string;
      name: string;
    };
    ranges: {
      type: string;
      events: Record<string, string>[];
    }[];
  }[];
}

export interface ExternalPackagesItemRes {
  package: string;
  repository: ExternalPackagesRepository;
  repositories: {
    name: string;
    repo_slug: string;
  }[];
  advisory: ExternalPackagesAdvisoryItem[];
  risk_rating: number;
  language: string;
  community: string | null;
  top_identities: {
    username: string;
    account_type: string;
    role: string;
    data: {
      num_contributions: number;
    };
  };
}

export interface ExternalCodeFiltersProps {
  license?: boolean;
  advisories?: boolean;
  risk_rating_filter?: string;
  language?: string;
  lte_total_score: number | string;
  gte_total_score: number | string;
}

export interface SecretDetectionItem {
  url: string;
  id: string;
  type: string;
  description: string;
  start_line: number;
  end_line: number;
  start_column: number;
  end_column: number;
  match: string;
  secret: string;
  file: string;
  organization: string;
  repository: string;
  repositories: string[];
  public: string;
  private: number;
  count: number;
  symlink_file: string;
  commit: string;
  entropy: string;
  user_login: string;
  display_name: string;
  users: string[];
  created: string;
  scan_date: string;
  rule_id: string;
  status: string;
  verified: boolean;
  files: string[];
}

export interface SecretDetectionObjRes extends ResponseObject {
  data: SecretDetectionItem[];
}

export interface SecretDetectionFiltersProps {
  status?: string;
  rule_id?: string;
  start_date?: string;
  end_date?: string;
}

export interface PolicyCriteriaItem {
  connectors: {
    connector_id: InputsSchemas;
    enabled: boolean;
  }[];
  criteria: string;
  criteria_satisfied: true;
}

export interface ReportObjItem {
  report_name: string;
  triggered_by: {
    email: string;
    name: string;
    nickname: string;
  };
  completed_at: number | null;
  filters_applied: any;
  file_type: string;
  triggered_at: number;
  status: string;
  file_path: string;
  api_path: string;
  hash_key: string;
}

export interface ReportObjRes {
  data: ReportObjItem[];
  total: number;
  items_page: number;
  page: number;
}
export interface ExposedRepositoriesItem {
  parents: { parent: string; imported_date: string }[];
  instances: number;
  isTopLevel: boolean;
  status: string;
  version: string;
  name: string;
  repo_slug: string;
  importer: string;
  import_at: string;
  importer_email: string;
  imported_date: string;
  isAffected: boolean;
  severity: string;
}

export interface ExposedRepositoriesObjRes extends ResponseObject {
  data: ExposedRepositoriesItem[];
}

export interface IdentityRiskReasons {
  data: {
    findings: {
      [severity: string]: {
        name: string;
        count: number;
      }[];
    };
  };
}

export interface IdentityAuditActivity {
  created: string;
  created_at: number;
  user_id: string;
  action: string;
  details: string;
  source: string;
}

export interface IdentityAuditActivityListRes {
  total: number;
  data: IdentityAuditActivity[];
  items_page: number;
  page: number;
}

export interface RecommendationStatusObj {
  request_id: string;
  action_request: {
    source: string;
    stream: string;
    requestDetails: {
      repo: string;
      owner: string;
      cursorValue: string;
    };
  };
  triggered_at: number;
  status: string;
}

export interface RecommendationsStatusRes {
  data: RecommendationStatusObj[];
  total: number;
  items_page: number;
  page: number;
}

export interface NotesObj {
  content: string;
  triggered_at: Date;
  created_by: {
    name: string;
    email: string;
  };
}

export interface CompliancePolicyData {
  compliance_id: string;
  policy_name: string;
  policy_display_name: string;
  compliance_standard: string;
  overall_policy_result: string;
  section_id: string;
  section_name: string;
  test_id: string;
  total_failed: number;
  total_ignored: number;
  total_passed: number;
  skipped: boolean;
  notes: NotesObj;
}

export interface ComplianceStandardDataRes {
  compliance_standard: string;
  data: CompliancePolicyData[];
  total_policies: number;
  policy_manual: number;
  policy_skipped: number;
  policy_passed: number;
  policy_failed: number;
  overall_result: number;
  last_evaluated: string;
}

export interface ComplianceOverviewObj {
  compliance_standard: string;
  compliance_title: string;
  total_failed: number;
  total_manual: number;
  total_passed: number;
  total_policies: number;
  total_skipped: number;
}

export interface ComplianceOverviewDataRes {
  data: ComplianceOverviewObj[];
  page: number;
  total: number;
}

export interface CompliancePolicyDetailsRes {
  checklist_status: {
    name: string;
    enabled: boolean;
  }[];
  compliance_id: string;
  compliance_standard: string;
  compliance_title: string;
  description: string;
  notes: NotesObj;
  overall_policy_result: string;
  policy_display_name: string;
  policy_name: string;
  section_id: number;
  test_type: string;
  total_failed: number;
  total_passed: number;
}
