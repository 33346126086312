import { ReactNode, SyntheticEvent } from 'react';
import {
  Tabs,
  Tab,
  TabProps,
  Input,
  TableCell,
  TableCellProps,
  Box,
  styled,
  Typography,
} from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';

import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';

interface StyledTabsProps {
  children?: ReactNode;
  value: number;
  onChange: (event: SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} />
))({
  minHeight: '25px',

  '& .MuiTabs-scroller': {
    height: '25px',
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiButtonBase-root': {
    padding: '1px',
    minHeight: '25px',
    width: 'auto',
  },
});

export const StyledTab = styled((props: TabProps) => <Tab {...props} />)(
  () => ({
    textTransform: 'none',
    fontSize: FontSizes.fontSize11,
    color: Colors.whiteTransparent07,
    border: `1px solid ${Colors.darkCyan}`,
    borderRightWidth: '0',
    minWidth: '70px',

    '&:first-of-type': {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    },

    '&:last-of-type': {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      borderRightWidth: '1px',
    },

    '&.MuiButtonBase-root': {
      fontSize: FontSizes.fontSize11,
      width: 'auto',
    },
    '&.Mui-selected': {
      color: Colors.blackPearl,
      backgroundColor: Colors.white,
    },
    '&.Mui-focusVisible': {
      backgroundColor: Colors.blackPearl,
    },
  }),
);

export const StyledInput = styled(Input)(() => ({
  fontSize: FontSizes.fontSize10,
  lineHeight: '24px',
  backgroundColor: 'transparent',
  color: Colors.white,

  '&.MuiInput-root:after': {
    borderBottom: `1px solid ${Colors.darkDesaturatedCyan}`,
    transform: 'none',
    transition: 'none',
  },

  '&.MuiInput-root.Mui-error:after': {
    borderColor: Colors.red,
  },

  '&.MuiInput-underline': {
    '&:hover:before': {
      borderBottom: `1px solid ${Colors.darkDesaturatedCyan}`,
    },
  },

  '&.Mui-focused': {
    borderColor: Colors.darkDesaturatedCyan,
  },
}));

interface TableHeaderCellProps extends TableCellProps {
  withSort?: boolean;
  clickSortHandler?: () => void;
  alignSortArrow?: boolean;
}

export const StyledTableHeaderCell = styled((props: TableHeaderCellProps) => (
  <TableCell {...props}>
    {props.children}
    {props.withSort && (
      <UnfoldMore
        onClick={props.clickSortHandler}
        sx={{
          position: 'absolute',
          width: '12px',
          height: '12px',
          marginLeft: props.alignSortArrow ? '25px' : '2px',
          top: 'calc(50% - 6px)',
          cursor: 'pointer',
        }}
      />
    )}
  </TableCell>
))({
  fontSize: FontSizes.fontSize9,
  fontWeight: '700',
  color: Colors.whiteTransparent07,
  backgroundColor: Colors.tiber,
  borderBottom: `2px solid ${Colors.plantation}`,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
});

export const StyledTableBodyCell = styled(TableCell)(() => ({
  fontSize: FontSizes.fontSize13,
  color: Colors.white,
  backgroundColor: 'transparent',
  borderBottom: `1px solid ${Colors.plantation}`,
  position: 'relative',
  height: '50px',
}));

export const TableFiltersContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const EmptyTableFiltersContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '25px',
}));

export const RightTableFiltersContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',
  marginLeft: 'auto',
}));

export const CellLabel = styled(Typography)(() => ({
  backgroundColor: Colors.blue,
  fontSize: FontSizes.fontSize10,
  color: Colors.white,
  padding: '5px 7px',
  borderRadius: '100px',
  width: '80px',
  textAlign: 'center',
  lineHeight: '12px',
}));
