import {
  AssetsRepositoriesObj,
  AssetsRepositoriesObjsRes,
  IdentitiesUserObj,
  IdentitiesUserObjRes,
} from './types';
import { getRepoOwnersString } from 'utils/assets/assets-utils';
import { getRepositoryStatus } from 'utils/map/map-utils';

export const transformIdentitiesUsersResponse = (
  response: IdentitiesUserObjRes,
): IdentitiesUserObj => {
  return {
    ...response,
    data: response?.data?.map((item) => ({
      ...item,
      identity_status: item.external ? 'External' : 'Internal',
    })),
  };
};

export const transformRepositoriesResponse = (
  response: AssetsRepositoriesObjsRes,
): AssetsRepositoriesObj => {
  return {
    ...response,
    data: response?.data?.map((item) => ({
      ...item,
      owners: getRepoOwnersString(item.owners_list),
      status: getRepositoryStatus(item),
    })),
  };
};

export const transDependencyGraphDataResponse = (response: any) => {
  const { edges, nodes } = response;
  return {
    nodes,
    edges,
  };
};
