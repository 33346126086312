import React, { ReactNode } from 'react';
import { Modal, Box, Typography } from '@mui/material';

import { Colors } from 'constants/colors';
import { StyledButton } from '../../button/button';
import { FontSizes } from '../../../constants/font-sizes';

interface Props {
  children?: ReactNode;
  title?: string;
  subTitle?: string;
  alignItems?: string;
  handleCloseModal?: () => void;
  handleConfirm?: () => void;
  modalWidth?: string;
  modalHeight?: string;
  isOpenModal: boolean;
  closeModalTitle?: string;
  confirmModalTitle?: string;
  backgroundColor?: string;
}

export const ModalContainer = ({
  children,
  title,
  subTitle,
  alignItems,
  handleCloseModal,
  handleConfirm,
  isOpenModal,
  closeModalTitle,
  confirmModalTitle,
  modalHeight,
  modalWidth,
  backgroundColor,
}: Props) => {
  return (
    <Modal onClose={handleCloseModal} open={isOpenModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: modalWidth || '500px',
          height: modalHeight || '250px',
          boxShadow: 24,
          backgroundColor: backgroundColor || Colors.blackPearl,
          display: 'flex',
          flexDirection: 'column',
          alignItems: alignItems ? alignItems : 'center',
          overflow: 'auto',
          padding: '30px 60px',
          borderRadius: '10px',
        }}
      >
        <Box sx={{ textAlign: 'left', width: '100%' }}>
          {title && (
            <Typography variant="h6" sx={{ fontSize: FontSizes.fontSize13 }}>
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography
              sx={{
                fontSize: FontSizes.fontSize10,
                color: Colors.whiteTransparent07,
              }}
            >
              {subTitle}
            </Typography>
          )}
        </Box>
        {children}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: 'auto',
            paddingTop: '15px',
          }}
        >
          {handleCloseModal && (
            <StyledButton
              variant="text"
              color="info"
              size="large"
              onClick={handleCloseModal}
            >
              {closeModalTitle ? closeModalTitle : 'Go Back'}
            </StyledButton>
          )}
          {handleConfirm && (
            <StyledButton
              sx={{ marginLeft: 'auto' }}
              onClick={handleConfirm}
              variant="contained"
              color="primary"
              size="large"
            >
              {confirmModalTitle ? confirmModalTitle : 'Confirm'}
            </StyledButton>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
