import { Box } from '@mui/material';
import { CalendarMonth } from '@mui/icons-material';

import { StyledTab, StyledTabs } from '../../styles';
import { DateRangeInput } from 'components/custom-date-picker/date-range-input/date-range-input';

export enum Periods {
  'weeks',
  'months',
  'years',
  'custom',
}

interface Props {
  tabValue: number;
  setTabValue: (tab: number) => void;
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  onSubmitHandler: () => void;
  tabsList: string[];
  minDate?: Date | null;
}

export const DatePicker = ({
  tabValue,
  setTabValue,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onSubmitHandler,
  tabsList,
  minDate,
}: Props) => {
  return (
    <Box sx={{ width: '100%', display: 'flex', gap: '20px' }}>
      <StyledTabs
        value={tabValue}
        onChange={(_, newValue) => setTabValue(newValue)}
      >
        {tabsList.map((tab) => (
          <StyledTab label={tab} key={tab} />
        ))}
        <StyledTab
          icon={<CalendarMonth sx={{ width: '14px', height: '14px' }} />}
          iconPosition="start"
          label="Custom"
        />
      </StyledTabs>
      {tabValue === tabsList.length && (
        <DateRangeInput
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onSubmitHandler={onSubmitHandler}
        />
      )}
    </Box>
  );
};
