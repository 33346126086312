import React from 'react';
import { Box, Chip } from '@mui/material';
import { Colors } from 'constants/colors';
import { ReactComponent as TriangleExclamation } from 'assets/icons/triangle-exclamation.svg';

export const SeverityChip = ({ severityScore }: { severityScore: number }) => {
  let chipColor = '',
    chipBackground = '',
    chipIcon: JSX.Element | undefined,
    chipText = '';
  switch (severityScore) {
    case 5:
      chipText = 'Critical';
      chipColor = '#F85455';
      chipBackground = 'rgba(248, 84, 85, 0.2);';
      chipIcon = (
        <TriangleExclamation
          color={chipColor}
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      );
      break;
    case 4:
      chipText = 'High';
      chipColor = '#F77A63';
      chipBackground = 'rgba(247, 122, 99, 0.2)';
      chipIcon = (
        <TriangleExclamation
          color={chipColor}
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      );
      break;
    case 3:
      chipText = 'Moderate';
      chipColor = '#FF9D2B';
      chipBackground = 'rgba(255, 157, 43, 0.2)';
      chipIcon = (
        <TriangleExclamation
          color={chipColor}
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      );
      break;
    case 2:
      chipText = 'Low';
      chipColor = '#FDD60D';
      chipBackground = 'rgba(253, 214, 13, 0.2)';
      chipIcon = (
        <TriangleExclamation
          color={chipColor}
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      );
      break;
    case 1:
      chipText = 'Info';
      chipColor = 'rgba(255, 255, 255, 0.7)';
      chipBackground = 'rgba(54, 81, 85, 0.2)';
      chipIcon = (
        <TriangleExclamation
          color={chipColor}
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      );
      break;
  }
  return (
    <Box display="flex" justifyContent="center">
      {severityScore ? (
        <Chip
          size="small"
          sx={{
            backgroundColor: chipBackground,
            color: chipColor || Colors.white,
            border: `1.8px solid ${chipColor}`,
            px: 1,
            cursor: 'inherit',
          }}
          icon={chipIcon}
          label={chipText}
          variant="outlined"
        />
      ) : null}
    </Box>
  );
};
