import { useAppSelector } from '../../hooks';
import { SortOrder } from 'utils/sort-order/sort';

export const useActiveSortedFieldSelector = (): string =>
  useAppSelector(({ assets }) => assets.activeSortedField);

export const useActiveSortedOrderSelector = (): SortOrder =>
  useAppSelector(({ assets }) => assets.activeSortedOrder);

export const useActivePipelineActivityWorkflowIdSelector = (): number | null =>
  useAppSelector(({ assets }) => assets.activePipelineActivityWorkflowId);

export const useActiveDependencyPackageSelector = (): string | undefined =>
  useAppSelector(({ assets }) => assets.activeDependencyPackage);
