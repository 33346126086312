import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  TableRow,
  Table,
  TableBody,
  TableHead,
  TableContainer,
} from '@mui/material';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';

import { useIdentitiesUserGroupsTeamsSelector } from 'store/modules/identities/identities.selector';
import {
  StyledTableBodyCell,
  StyledTableHeaderCell,
} from 'components/table/styles';
import { useIsPlainModalOpenSelector } from 'store/modules/modals/modals.selector';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import { TableCellWithLink } from 'components/table/table-components/table-components';
import { paths } from 'constants/routes';
import { setSearchQuery } from 'store/modules/teams/teams.reducer';

export const GroupTeamModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const teams = useIdentitiesUserGroupsTeamsSelector();

  const isGroupTeamOpenModal = useIsPlainModalOpenSelector(
    PlainModals.GROUP_TEAM_MODAL,
  );

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.GROUP_TEAM_MODAL));
  };

  return (
    <ModalContainer
      isOpenModal={isGroupTeamOpenModal}
      title="Group/Team"
      handleCloseModal={handleCloseModal}
      modalWidth="700px"
      modalHeight="400px"
    >
      <TableContainer sx={{ marginTop: '25px', maxHeight: '750px' }}>
        <Table
          stickyHeader
          sx={{ minWidth: '550px', backgroundColor: 'transparent' }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell>{`Name (${teams?.length})`}</StyledTableHeaderCell>
              <StyledTableHeaderCell>Permissions</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams?.map((item, index) => (
              <TableRow key={index}>
                <StyledTableBodyCell>
                  <TableCellWithLink
                    handleClick={() => {
                      if (Object.keys(item)[0] === 'direct') return;
                      navigate(paths.teams);
                      handleCloseModal();
                      dispatch(setSearchQuery(Object.keys(item)[0]));
                    }}
                    isActive={Object.keys(item)[0] !== 'direct'}
                    itemTitle={Object.keys(item)[0]}
                    align="left"
                  />
                </StyledTableBodyCell>
                <StyledTableBodyCell align="left">
                  {Object.values(item)[0]}
                </StyledTableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalContainer>
  );
};
