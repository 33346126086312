import { InputsSchemas } from '../../../types';

export const connectorMappedToPDFDoc = {
  [InputsSchemas.GITHUB]:
    'https://cdn.identityscience.ai/pdfs/github-connector-dark.pdf',
  [InputsSchemas.JIRA]:
    'https://cdn.identityscience.ai/pdfs/jira-connector-dark.pdf',
  [InputsSchemas.AZURE_AD]:
    'https://cdn.identityscience.ai/pdfs/azure-connector-dark.pdf',
  [InputsSchemas.GITLAB]:
    'https://cdn.identityscience.ai/pdfs/gitlab-connector-dark.pdf',
  [InputsSchemas.COMPASS]:
    'https://cdn.identityscience.ai/pdfs/compass-connector-dark.pdf',
  [InputsSchemas.OKTA]:
    'https://cdn.identityscience.ai/pdfs/okta-connector-dark.pdf',
  [InputsSchemas.BITBUCKET]:
    'https://cdn.identityscience.ai/pdfs/bitbucket-connector-dark.pdf',
  [InputsSchemas.JENKINS]:
    'https://cdn.identityscience.ai/pdfs/jenkins-connector-dark.pdf',
  [InputsSchemas.CIRCLE_CI]:
    'https://cdn.identityscience.ai/pdfs/circle-ci-connector-dark.pdf',
  [InputsSchemas.AZURE_DEVOPS]:
    'https://cdn.identityscience.ai/pdfs/azure-devops-connector-dark.pdf',
  [InputsSchemas.GOOGLE_DIR]:
    'https://cdn.identityscience.ai/pdfs/google-dir-connector-dark.pdf',
  [InputsSchemas.SLACK]:
    'https://cdn.identityscience.ai/pdfs/slack-connector-dark.pdf',
  [InputsSchemas.SPLUNK]:
    'https://cdn.identityscience.ai/pdfs/splunk-connector-dark.pdf',
  [InputsSchemas.TEAMS]:
    'https://cdn.identityscience.ai/pdfs/teams-connector-dark.pdf',
  [InputsSchemas.FLOCK]:
    'https://cdn.identityscience.ai/pdfs/flock-connector-dark.pdf',
};
