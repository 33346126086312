import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  styled,
  TextareaAutosize,
} from '@mui/material';

import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';

export const StyledFormControl = styled(FormControl)(() => ({
  paddingLeft: '0',
}));

export const StyledFormLabel = styled(FormLabel)(() => ({
  fontSize: FontSizes.fontSize13,
  fontWeight: '600',
  lineHeight: '12px',

  '& .Mui-focused': {
    color: Colors.white,
  },
}));

export const StyledFormHelperText = styled(FormHelperText)(() => ({
  fontSize: FontSizes.fontSize13,
  fontWeight: '600',
  lineHeight: '24px',
  color: Colors.white,
  opacity: '0.53',
  marginLeft: '0',
}));

export const StyledInput = styled(Input)(() => ({
  fontSize: FontSizes.fontSize13,
  lineHeight: '24px',
  backgroundColor: 'transparent',
  color: Colors.white,

  '& .Mui-disabled': {
    color: Colors.white,
    WebkitTextFillColor: Colors.white,
  },

  '& .MuiInput-root:after': {
    borderBottom: '1px solid #365155',
    transform: 'none',
    transition: 'none',
  },

  '& .MuiInput-root.Mui-error:after': {
    borderColor: Colors.red,
  },

  '& .MuiInput-underline': {
    '&:hover:before': {
      borderBottom: '1px solid #365155',
    },
  },

  '& .Mui-focused': {
    borderColor: Colors.darkDesaturatedCyan,
  },
}));

export const StyledCheckbox = styled(Checkbox)(() => ({
  color: Colors.white,

  '& .Mui-checked': {
    color: Colors.white,
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': {
    fontSize: FontSizes.fontSize13,
  },
}));

export const StyledTextArea = styled(TextareaAutosize)<{ error: boolean }>(
  ({ error }) => ({
    backgroundColor: Colors.tiber,
    color: Colors.white,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomColor: error ? Colors.red : Colors.darkDesaturatedCyan,
    outline: 'none',
    resize: 'vertical',
    maxHeight: '300px',
  }),
);
