import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { SortOrder } from 'utils/sort-order/sort';

export interface TeamsState {
  activeSortedField: string;
  activeSortedOrder: SortOrder;
  searchQuery: string;
}

const initialState: TeamsState = {
  activeSortedField: 'name',
  activeSortedOrder: SortOrder.Desc,
  searchQuery: '',
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setActiveSortedField: (
      state: TeamsState,
      action: PayloadAction<string>,
    ) => {
      state.activeSortedField = action.payload;
    },
    setActiveSortedOrder: (
      state: TeamsState,
      action: PayloadAction<SortOrder>,
    ) => {
      state.activeSortedOrder = action.payload;
    },
    setSearchQuery: (state: TeamsState, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
  },
});

export const { setActiveSortedField, setActiveSortedOrder, setSearchQuery } =
  teamsSlice.actions;

export const teamsReducer = teamsSlice.reducer;
