import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  TableRow,
  Table,
  TableBody,
  TableHead,
  TableContainer,
} from '@mui/material';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';

import {
  StyledTableBodyCell,
  StyledTableHeaderCell,
} from 'components/table/styles';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import { TableCellWithLink } from 'components/table/table-components/table-components';
import { paths } from 'constants/routes';
import { Member } from 'store/apis/types';

export const MembersModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const metaData = useGetModalMetaData();

  //@ts-ignore
  const members: Member[] = metaData?.members;

  const isOpenModal = useIsPlainModalOpenSelector(
    PlainModals.TEAMS_MEMBERS_MODAL,
  );

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.TEAMS_MEMBERS_MODAL));
  };

  return (
    <ModalContainer
      isOpenModal={isOpenModal}
      title="Group/Team"
      handleCloseModal={handleCloseModal}
      modalWidth="700px"
      modalHeight="400px"
    >
      <TableContainer sx={{ marginTop: '25px', maxHeight: '750px' }}>
        <Table
          stickyHeader
          sx={{ minWidth: '550px', backgroundColor: 'transparent' }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell align="left">Name</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members?.map((item, index) => (
              <TableRow key={index}>
                <StyledTableBodyCell>
                  <TableCellWithLink
                    handleClick={() => {
                      navigate(`${paths.identities}/${item.login}`);
                      handleCloseModal();
                    }}
                    isActive={true}
                    itemTitle={item.name || item.login}
                    align="left"
                  />
                </StyledTableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalContainer>
  );
};
