import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  transformExternalUsersResponse,
  transformHighRiskOSSResponse,
  transformInactiveIdentitiesResponse,
  transformSelfApprovedPullRequestsResponse,
  transformStaleRepositoriesResponse,
  transformUnprotectedDefaultBranchesResponse,
} from './chart-helpers';
import {
  transDependencyGraphDataResponse,
  transformIdentitiesUsersResponse,
  transformRepositoriesResponse,
} from './helpers';
import {
  AssetsDetailsActivityRes,
  AssetsDetailsBranchesObjRes,
  AssetsDetailsObjRes,
  AssetsDetailsPipelineRes,
  AssetsPipelineRunsDetailsRes,
  AssetsRepositoriesObj,
  AssetsRepositoriesObjsRes,
  BranchProtectionObjRes,
  ChartData,
  ComplianceOverviewDataRes,
  CompliancePolicyDetailsRes,
  DetectionRes,
  ExposedRepositoriesObjRes,
  ExternalCodeFiltersProps,
  ExternalPackagesObjRes,
  GeneralResponseItem,
  IdentitiesUserAccessObjRes,
  IdentitiesUserActivityObjRes,
  IdentitiesUserDetails,
  IdentitiesUserObj,
  IdentitiesUserObjRes,
  IdentitiesUserPipelineActivityObjRes,
  IdentityAuditActivityListRes,
  OpenSourceDependenciesItemRes,
  OpenSourceDependenciesObjRes,
  PolicyCriteriaItem,
  RecommendationsStatusRes,
  ReportObjRes,
  RepositoriesContributorObjRes,
  SecretDetectionFiltersProps,
  SecretDetectionItem,
  SecretDetectionObjRes,
  TeamAssetItemRes,
  TeamDetailsRes,
  TeamMemberItemRes,
  TeamsObjRes,
  UsersSettingsItemRes,
} from './types';
import { IntegrationItem } from 'pages/connectors/types';
import { Filter } from '../modules/filters/filters.reducer';
import { FiltersProps } from 'pages/assets/components/assets-multi-filter/assets-multi-filter';
import moment from 'moment';
import { AnyNaptrRecord } from 'dns';

interface RawNodeDatum {
  name: string;
  attributes?: Record<string, string | number | boolean>;
  children?: RawNodeDatum[];
}

export const generateBaseApi = (
  getAccessTokenSilently: () => Promise<string>,
) =>
  createApi({
    reducerPath: 'baseApi',
    baseQuery: fetchBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL,
      prepareHeaders: async (headers) => {
        const token = await getAccessTokenSilently();
        if (token) {
          headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
      },
    }),
    tagTypes: [
      'CurrentConnections',
      'GetIdentitiesUserList',
      'GetRepositoriesList',
      'GetPolicies',
      'GetBranchesList',
      'GetTeamsList',
      'GetTeamDetailsObj',
      'GetDetectionList',
      'GetUsers',
      'GetIdentitiesUserDetails',
      'GetAssetsDetailsObj',
      'GetRepositoriesExternalList',
      'RepositoriesExternalTree',
      'GetOpensourceDependenciesDetails',
      'GetDetectedSecretsList',
      'GetOnboardingChecklistStatus',
      'GetPolicyCriteriaByName',
      'GetPolicyTrendDataByName',
      'GetPolicyByName',
      'GetSecretByType',
      'GetSettingsInfo',
      'GetExposedRepositories',
      'GetContributors',
      'GetIdentityUnusedAccess',
      'GetTeamRepoRecommendation',
      'GetTeamMemberRecommendation',
      'GetPolicySchedule',
      'GetRepositoryContributors',
      'GetJITRequests',
      'GetPersonalJITRequests',
      'GetRepositoryContributors',
      'GetTokens',
    ],
    endpoints: (builder) => ({
      getInactiveIdentities: builder.query<ChartData, void>({
        query: () => ({
          url: '/users/inactive/chart',
        }),
        transformResponse: (response: Record<string, string>) => {
          return transformInactiveIdentitiesResponse(response);
        },
      }),
      getHighRiskPackages: builder.query<ChartData, void>({
        query: () => ({
          url: '/repositories/external/high_chart',
        }),
        transformResponse: (response: Record<string, string>) => {
          return transformHighRiskOSSResponse(response);
        },
      }),
      getAllDashboardDetections: builder.query<any[], void>({
        query: () => ({
          url: '/rules/get_all_detections',
        }),
      }),
      getUserInfo: builder.query<any, void>({
        query: () => ({
          url: '/users/self',
        }),
      }),
      getUnprotectedDefaultBranches: builder.query<ChartData, void>({
        query: () => ({
          url: '/analytics/charts/default-branches',
        }),
        transformResponse: (response: Record<string, string>) => {
          return transformUnprotectedDefaultBranchesResponse(response);
        },
      }),
      getExternalUsers: builder.query<ChartData, void>({
        query: () => ({
          url: '/users/external/chart',
        }),
        transformResponse: (response: Record<string, string>) => {
          return transformExternalUsersResponse(response);
        },
      }),
      getSelfApprovedPullRequests: builder.query<ChartData, void>({
        query: () => ({
          url: '/analytics/pr_repo_types/test',
        }),
        transformResponse: (response: Record<string, string>) => {
          return transformSelfApprovedPullRequestsResponse(response);
        },
      }),
      getAllAvailableIntegrations: builder.query<IntegrationItem[], void>({
        query: () => '/connectors',
      }),
      getAllCurrentConnections: builder.query<IntegrationItem[], void>({
        query: () => '/connectors/connections',
        providesTags: ['CurrentConnections'],
      }),
      editConnection: builder.mutation<IntegrationItem, IntegrationItem>({
        query: (connection) => ({
          url: `/connectors/connections/${connection.connector_id}`,
          method: 'PUT',
          body: { connection },
        }),
        invalidatesTags: [
          'CurrentConnections',
          'GetOnboardingChecklistStatus',
          'GetPolicyCriteriaByName',
        ],
      }),
      createConnection: builder.mutation<
        { status: string; message: string },
        IntegrationItem
      >({
        query: (connection) => ({
          url: `/connectors/connections/${connection.connector_id}`,
          method: 'POST',
          body: { ...connection },
        }),
        invalidatesTags: [
          'CurrentConnections',
          'GetOnboardingChecklistStatus',
          'GetPolicyCriteriaByName',
        ],
      }),
      deleteConnection: builder.mutation<
        { status: string; message: string },
        string
      >({
        query: (connectionId) => ({
          url: `/connectors/connections/${connectionId}`,
          method: 'DELETE',
          body: { connection_id: connectionId },
        }),
        invalidatesTags: ['CurrentConnections', 'GetPolicyCriteriaByName'],
      }),
      getPolicyByName: builder.query<any, string>({
        query: (policyName) => ({
          url: `/rules/policy/${policyName}`,
        }),
        providesTags: ['GetPolicyByName'],
      }),
      getTestPolicyData: builder.query<
        any,
        { policyName?: string; userName?: string; matchPredicate?: any }
      >({
        query: ({ policyName, userName, matchPredicate }) => {
          return {
            url:
              userName && userName.length > 0 && !matchPredicate
                ? `/rules/test-policy/${policyName}/${userName}`
                : `/rules/test-policy/${policyName}`,
            params: {
              match_predicate: matchPredicate,
            },
          };
        },
      }),
      createCustomPolicy: builder.mutation<void, any>({
        query: (body) => ({
          url: '/rules/policy',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['GetPolicies'],
      }),
      generateToken: builder.query<any, void>({
        query: () => ({
          url: '/connectors/connections/compass',
          method: 'GET',
        }),
      }),
      getTokens: builder.mutation<any, void>({
        query: () => ({
          url: '/admin/get-compass-token',
          method: 'POST',
        }),
      }),
      getPolicies: builder.query<any, void>({
        query: () => ({
          url: '/rules/policy',
        }),
        providesTags: ['GetPolicies'],
      }),
      togglePolicy: builder.mutation<any, string>({
        query: (policyName) => ({
          url: `/rules/policy/${policyName}/change-status`,
          method: 'POST',
        }),
        invalidatesTags: [
          'GetPolicies',
          'GetOnboardingChecklistStatus',
          'GetPolicyCriteriaByName',
        ],
      }),
      editCustomPolicy: builder.mutation<void, any>({
        query: (body) => ({
          url: `/rules/policy/${body.name}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: [
          'GetPolicies',
          'GetOnboardingChecklistStatus',
          'GetPolicyCriteriaByName',
        ],
      }),
      removePolicy: builder.mutation<void, string>({
        query: (email) => ({
          url: `/rules/policy/${email}`,
          method: 'DELETE',
        }),
      }),
      getPolicySchedule: builder.query<any, string>({
        query: (policyName) => ({
          url: `/scheduler/${policyName}/get-schedule`,
        }),
        providesTags: ['GetPolicySchedule'],
      }),
      updatePolicySchedule: builder.mutation<void, any>({
        query: ({ policy, schedule, body }) => ({
          url: `/scheduler/${policy}/modify-schedule/${schedule}`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['GetPolicySchedule'],
      }),
      createPolicySchedule: builder.mutation<void, any>({
        query: ({ policy, body }) => ({
          url: `/scheduler/${policy.name}/create-schedule`,
          method: 'POST',
          body,
        }),
        invalidatesTags: [],
      }),
      removePolicySchedule: builder.mutation<void, any>({
        query: ({ policy, schedule }) => ({
          url: `/scheduler/${policy}/remove-schedule/${schedule}`,
          method: 'DELETE',
        }),
      }),
      getPolicyCriteriaByName: builder.query<PolicyCriteriaItem[], string>({
        query: (policyName) => ({
          url: `/rules/policy/${policyName}/criteria`,
        }),
        providesTags: ['GetPolicyCriteriaByName'],
      }),
      getPolicyTrendDataByName: builder.query<any, string>({
        query: (policyName) => ({
          url: `/rules/policy/${policyName}/timeseries`,
        }),
        providesTags: ['GetPolicyTrendDataByName'],
      }),
      getBranchProtectionData: builder.query<
        BranchProtectionObjRes,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters: Filter;
          searchQuery: string;
        }
      >({
        query: ({
          pageNum,
          sortBy,
          tableFilters: {
            only_environment,
            only_important,
            only_protected,
            only_workflow,
            only_default,
          },
          searchQuery,
        }) => ({
          url: '/analytics/branch_protection',
          params: {
            items_page: 30,
            page: pageNum,
            sort_by: sortBy,
            only_environment,
            only_important,
            only_protected,
            only_workflow,
            only_default,
            query: searchQuery,
          },
        }),
        providesTags: ['GetBranchesList'],
      }),
      getTeamsList: builder.query<
        TeamsObjRes,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters?: Filter;
          searchQuery?: string;
        }
      >({
        query: ({ pageNum, sortBy, tableFilters, searchQuery }) => ({
          url: '/users/teams',
          params: {
            items_page: 30,
            page: pageNum,
            sort_by: sortBy,
            is_team_status_active: tableFilters?.is_team_status_active,
            only_watched: tableFilters?.only_watched,
            only_hidden: tableFilters?.only_hidden,
            query: searchQuery,
          },
        }),
        providesTags: ['GetTeamsList'],
      }),
      getTeamDetails: builder.query<TeamDetailsRes, string>({
        query: (teamId) => `/users/teams/${teamId}/details`,
        providesTags: ['GetTeamDetailsObj'],
      }),
      getTeamReposList: builder.query<
        TeamAssetItemRes,
        {
          id: string;
          pageNum: number;
          sortBy?: string;
        }
      >({
        query: ({ id, pageNum, sortBy }) => ({
          url: `/users/teams/${id}/assets`,
          params: { page: pageNum, sort_by: sortBy },
        }),
      }),
      getTeamMembersList: builder.query<
        TeamMemberItemRes,
        {
          id: string;
          pageNum: number;
          sortBy?: string;
        }
      >({
        query: ({ id, pageNum, sortBy }) => ({
          url: `/users/teams/${id}/members`,
          params: { page: pageNum, sort_by: sortBy },
        }),
      }),
      sendRiskAssessmentReportOnEmail: builder.mutation<void, string>({
        query: (email) => ({
          url: `/analytics/reports/risk_assessment/${email}`,
          method: 'GET',
        }),
      }),
      getDetectionsList: builder.query<
        DetectionRes,
        {
          pageNum: number;
          sortBy?: string;
          searchQuery?: string;
          tableFilters: {
            status?: string;
            severity_filter?: string;
          };
        }
      >({
        query: ({ pageNum, sortBy, searchQuery, tableFilters }) => {
          const params: Record<string, any> = {
            items_page: 10,
            page: pageNum,
            sort_by: sortBy,
            query: searchQuery,
          };
          if (tableFilters?.status !== 'all') {
            params.status = tableFilters?.status;
          }
          if (tableFilters?.severity_filter !== 'all') {
            params.severity_filter = tableFilters?.severity_filter;
          }
          return {
            url: '/rules/detection',
            method: 'GET',
            params,
          };
        },
        providesTags: ['GetDetectionList'],
      }),
      changeDetectionStatus: builder.mutation<
        void,
        { detectionId: string; status: string }
      >({
        query: ({ detectionId, status }) => ({
          url: `/rules/detection/${detectionId}/status`,
          method: 'PUT',
          params: { status },
        }),
        invalidatesTags: ['GetDetectionList'],
      }),
      getUsers: builder.query<UsersSettingsItemRes[], void>({
        query: () => '/admin/users',
        providesTags: ['GetUsers'],
      }),
      inviteUser: builder.mutation<GeneralResponseItem, any>({
        query: (payload) => ({
          url: '/admin/users/invite',
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetUsers', 'GetOnboardingChecklistStatus'],
      }),
      deleteUser: builder.mutation<void, string>({
        query: (userId) => ({
          url: `/admin/users/${userId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['GetUsers'],
      }),
      blockOrUnblockUser: builder.mutation<void, string>({
        query: (userId) => ({
          url: `/admin/users/block/${userId}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['GetUsers'],
      }),
      resetPassword: builder.mutation<void, string>({
        query: (email) => ({
          url: `admin/users/password/reset?email=${email}`,
          method: 'POST',
        }),
        invalidatesTags: ['GetUsers'],
      }),
      getIdentitiesUserAccessList: builder.query<
        IdentitiesUserAccessObjRes,
        {
          id: string;
          pageNum: number;
          sortBy?: string;
          tableFilters: Filter;
          searchQuery?: string;
          itemsPerPage?: number;
        }
      >({
        query: ({
          id,
          pageNum,
          sortBy,
          tableFilters: { is_critical, permissions, only_overprivilege },
          searchQuery,
          itemsPerPage,
        }) => ({
          url: `/users/${id}/assets`,
          params: {
            page: pageNum,
            items_page: itemsPerPage || 40,
            sort_by: sortBy,
            query: searchQuery,
            is_critical,
            permissions,
            only_overprivilege,
          },
        }),
      }),
      getIdentitiesUserDetails: builder.query<IdentitiesUserDetails, string>({
        query: (userId) => `/users/${userId}`,
        providesTags: ['GetIdentitiesUserDetails'],
      }),
      getOpensourceDependenciesDetails: builder.query<
        OpenSourceDependenciesItemRes,
        string
      >({
        query: (userId) => `/repositories/external/list/${userId}`,
        providesTags: ['GetOpensourceDependenciesDetails'],
      }),
      getIdentitiesUserActivityList: builder.query<
        IdentitiesUserActivityObjRes,
        {
          id: string;
          pageNum: number;
          startDate?: string;
          endDate?: string;
          searchQuery: string;
        }
      >({
        query: ({ id, pageNum, startDate, endDate, searchQuery }) => ({
          url: `/users/${id}/activity`,
          params: {
            page: pageNum,
            items_page: 40,
            start_date: startDate,
            end_date: endDate,
            query: searchQuery,
          },
        }),
      }),
      getIdentitiesUserPipelineActivityList: builder.query<
        IdentitiesUserPipelineActivityObjRes,
        { id: string; pageNum: number; startDate?: string; endDate?: string }
      >({
        query: ({ id, pageNum, startDate, endDate }) => ({
          url: `/users/${id}/activity/pipeline`,
          params: {
            page: pageNum,
            items_page: 40,
            start_date: startDate,
            end_date: endDate,
          },
        }),
      }),
      getIdentitiesUserAlertList: builder.query<
        any,
        {
          id: string;
          sortBy: string;
        }
      >({
        query: ({ id, sortBy }) => ({
          url: `/users/${id}/alerts`,
          params: {
            sort_by: sortBy,
          },
        }),
      }),
      getIdentitiesSecretsRiskList: builder.query<
        any,
        {
          id: string;
          sortBy: string;
          searchQuery: string;
          pageNum: number;
          tableFilters?: SecretDetectionFiltersProps;
        }
      >({
        query: ({ id, sortBy, searchQuery, pageNum, tableFilters }) => ({
          url: `/users/${id}/alerts/secret`,
          params: {
            sort_by: sortBy,
            // query: searchQuery,
            page: pageNum,
            items_page: 10,
            status: tableFilters?.status,
            query: tableFilters?.rule_id,
          },
        }),
      }),
      getIdentitiesOSSRiskList: builder.query<
        DetectionRes,
        {
          id: string;
          sortBy: string;
          searchQuery: string;
          pageNum: number;
          tableFilters?: any;
        }
      >({
        query: ({ id, sortBy, searchQuery, pageNum, tableFilters }) => ({
          url: `/users/${id}/alerts/oss`,
          params: {
            sort_by: sortBy,
            query: searchQuery,
            page: pageNum,
            items_page: 10,
            status: tableFilters?.status,
          },
        }),
      }),
      getIdentityUnusedAccess: builder.query({
        query: (userId) => ({
          url: `users/${userId}/unused-access`,
        }),
        providesTags: ['GetIdentityUnusedAccess'],
      }),
      getRepositoriesContributorsList: builder.query<
        RepositoriesContributorObjRes,
        {
          pageNum: number;
          tableFilters: Filter;
          searchQuery: string;
          id: string;
        }
      >({
        query: ({
          id,
          tableFilters: { only_owners, only_overprivilege },
          searchQuery,
          pageNum,
        }) => ({
          url: `/repositories/${id}/contributors`,
          params: {
            items_page: 20,
            page: pageNum,
            query: searchQuery,
            only_owners,
            only_overprivilege,
          },
        }),
        providesTags: ['GetRepositoryContributors'],
      }),
      getAssetsDetailsPipelinesList: builder.query<
        AssetsDetailsPipelineRes[],
        { id: string; pageNum: number; startDate?: string; endDate?: string }
      >({
        query: ({ id, pageNum, startDate, endDate }) => ({
          url: `/repositories/${id}/pipeline/activity`,
          params: {
            items_page: 20,
            page: pageNum,
            start_date: startDate,
            end_date: endDate,
          },
        }),
      }),
      getAssetsDetailsActivityList: builder.query<
        AssetsDetailsActivityRes[],
        { id: string; pageNum: number; startDate?: string; endDate?: string }
      >({
        query: ({ id, pageNum, startDate, endDate }) => ({
          url: `/repositories/${id}/activity`,
          params: {
            items_page: 20,
            page: pageNum,
            start_date: startDate,
            end_date: endDate,
          },
        }),
      }),
      getAssetsPipelineActivityRunsDetails: builder.query<
        AssetsPipelineRunsDetailsRes[],
        number | null
      >({
        query: (workflowId) =>
          `analytics/workflow/runs?workflowid=${workflowId}`,
      }),
      getAssetsDetailsBranchesObj: builder.query<
        AssetsDetailsBranchesObjRes,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters: Filter;
          searchQuery: string;
          id: string;
        }
      >({
        query: ({
          id,
          pageNum,
          sortBy,
          tableFilters: {
            only_environment,
            only_important,
            only_protected,
            only_workflow,
          },
          searchQuery,
        }) => ({
          url: `/repositories/${id}/branches`,
          params: {
            items_page: 20,
            page: pageNum,
            sort_by: sortBy,
            only_environment,
            only_important,
            only_protected,
            only_workflow,
            query: searchQuery,
          },
        }),
      }),
      getExternalPackagesObj: builder.query<
        ExternalPackagesObjRes,
        { id: string; pageNum: number; searchQuery: string }
      >({
        query: ({ id, pageNum, searchQuery }) => ({
          url: `/repositories/${id}/external`,
          params: {
            page: pageNum,
            items_page: 20,
            query: searchQuery,
          },
        }),
      }),
      getAssetContainers: builder.query<
        AnyNaptrRecord,
        { id: string; pageNum: number; searchQuery: string }
      >({
        query: ({ id, pageNum, searchQuery }) => ({
          url: `/repositories/${id}/containers`,
          params: {
            page: pageNum,
            items_page: 20,
            query: searchQuery,
          },
        }),
      }),
      getAssetCompliance: builder.query<
        any,
        { id: string; pageNum: number; searchQuery: string }
      >({
        query: ({ id, pageNum, searchQuery }) => ({
          url: `/repositories/${id}/compliance`,
          params: {
            page: pageNum,
            items_page: 10,
            query: searchQuery,
          },
        }),
      }),
      getAssetsDetailsObj: builder.query<AssetsDetailsObjRes, string>({
        query: (repoSlug) => `/repositories/${repoSlug}`,
        providesTags: ['GetAssetsDetailsObj'],
      }),
      changeRepoHide: builder.mutation<void, string>({
        query: (repoSlug) => ({
          url: `/repositories/${repoSlug}/hide`,
          method: 'PATCH',
        }),
        invalidatesTags: ['GetRepositoriesList', 'GetAssetsDetailsObj'],
      }),
      changeRepoWatch: builder.mutation<void, string>({
        query: (repoSlug) => ({
          url: `/repositories/${repoSlug}/watch`,
          method: 'PATCH',
        }),
        invalidatesTags: ['GetRepositoriesList', 'GetAssetsDetailsObj'],
      }),
      changeIdentitiesUserHide: builder.mutation<void, string>({
        query: (userId) => ({
          url: `/users/${userId}/hide`,
          method: 'PATCH',
        }),
        invalidatesTags: ['GetIdentitiesUserList', 'GetIdentitiesUserDetails'],
      }),
      changeIdentitiesUserWatch: builder.mutation<void, string>({
        query: (userId) => ({
          url: `/users/${userId}/watch`,
          method: 'PATCH',
        }),
        invalidatesTags: ['GetIdentitiesUserList', 'GetIdentitiesUserDetails'],
      }),
      changeTeamHide: builder.mutation<void, string>({
        query: (teamId) => ({
          url: `/users/teams/${teamId}/hide`,
          method: 'PATCH',
        }),
        invalidatesTags: ['GetTeamDetailsObj'],
      }),
      changeTeamWatch: builder.mutation<void, string>({
        query: (teamsId) => ({
          url: `/users/teams/${teamsId}/watch`,
          method: 'PATCH',
        }),
        invalidatesTags: ['GetTeamDetailsObj'],
      }),
      getStaleRepositories: builder.query<ChartData, void>({
        query: () => ({
          url: '/repositories/stale/chart',
        }),
        transformResponse: (response: Record<string, string>) => {
          return transformStaleRepositoriesResponse(response);
        },
      }),
      getRepositoriesList: builder.query<
        AssetsRepositoriesObjsRes,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters: FiltersProps;
          searchQuery: string;
        }
      >({
        query: ({ pageNum, sortBy, tableFilters, searchQuery }) => ({
          url: '/repositories',
          params: {
            items_page: 30,
            page: pageNum,
            sort_by: sortBy,
            critical: tableFilters?.critical,
            status: tableFilters?.status,
            query: searchQuery,
            only_hidden: tableFilters?.only_hidden,
            only_watched: tableFilters?.only_watched,
            only_production: tableFilters?.only_production,
          },
        }),
        providesTags: ['GetRepositoriesList'],
        transformResponse: (
          response: AssetsRepositoriesObjsRes,
        ): AssetsRepositoriesObj => {
          return transformRepositoriesResponse(response);
        },
      }),
      getDependencyGraphData: builder.query<any, Record<string, any>>({
        query: (params) => {
          return {
            url: '/analytics/entity-graph/access',
            params,
          };
        },
        transformResponse: (response: any) => {
          return transDependencyGraphDataResponse(response);
        },
      }),
      getIdentitiesUserList: builder.query<
        IdentitiesUserObj,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters: Filter;
          searchQuery?: string;
          itemsPerPage?: number;
        }
      >({
        query: ({
          pageNum,
          sortBy,
          tableFilters,
          searchQuery,
          itemsPerPage,
        }) => ({
          url: '/users',
          params: {
            items_page: itemsPerPage || 30,
            page: pageNum,
            sort_by: sortBy,
            account_status: tableFilters?.account_status,
            team_member: tableFilters?.team_member,
            internal_account: tableFilters?.internal_account,
            external_account: tableFilters?.external_account,
            highest_severity: tableFilters?.highest_severity,
            min_overprivilege_score: tableFilters?.min_overprivilege_score,
            unknown: tableFilters?.unknown,
            account_type: tableFilters?.account_type,
            query: searchQuery,
            only_watched: tableFilters?.only_watched,
            only_hidden: tableFilters?.only_hidden,
          },
        }),
        providesTags: ['GetIdentitiesUserList'],
        transformResponse: (
          response: IdentitiesUserObjRes,
        ): IdentitiesUserObj => {
          return transformIdentitiesUsersResponse(response);
        },
      }),
      getRepositoriesExternalList: builder.query<
        OpenSourceDependenciesObjRes,
        {
          pageNum: number;
          searchQuery: string;
          sortBy?: string;
          tableFilters: ExternalCodeFiltersProps;
        }
      >({
        query: ({
          pageNum,
          searchQuery,
          sortBy,
          tableFilters: {
            license,
            advisories,
            risk_rating_filter,
            language,
            lte_total_score,
            gte_total_score,
          },
        }) => ({
          url: `/repositories/external/list`,
          params: {
            page: pageNum,
            items_page: 20,
            query: searchQuery,
            sort_by: sortBy,
            license_filter: license,
            advisories,
            risk_rating_filter,
            language,
            gte_total_score:
              lte_total_score && Number(lte_total_score) === 100
                ? 68
                : lte_total_score && Number(lte_total_score) === 67
                ? 41
                : 0,
            lte_total_score:
              typeof lte_total_score === 'string'
                ? Number(lte_total_score)
                : lte_total_score,
          },
        }),
        providesTags: ['GetRepositoriesExternalList'],
      }),
      getRepositoriesExternalTree: builder.query<RawNodeDatum, string>({
        query: (repoSlug) => `/repositories/${repoSlug}/external/tree`,
        providesTags: ['RepositoriesExternalTree'],
      }),
      getOnboardingChecklistStatus: builder.query<Filter, void>({
        query: () => ({
          url: '/users/onboarding-checklist/status',
        }),
        providesTags: ['GetOnboardingChecklistStatus'],
      }),
      getDetectedSecretsList: builder.query<
        SecretDetectionObjRes,
        {
          pageNum: number;
          // searchQuery: string;
          sortBy?: string;
          tableFilters?: SecretDetectionFiltersProps;
          startDate?: string;
          endDate?: string;
        }
      >({
        query: ({
          pageNum,
          // searchQuery,
          sortBy,
          tableFilters,
          startDate,
          endDate,
        }) => ({
          url: `/secrets/detections`,
          params: {
            page: pageNum,
            items_page: 1000000,
            // query: searchQuery,
            sort_by: sortBy,
            status: tableFilters?.status,
            query: tableFilters?.rule_id,
            start_date: startDate,
            end_date: endDate,
          },
        }),
        transformResponse: (response: {
          detections?: SecretDetectionItem[];
          page: number;
          items_page: number;
          total?: number;
        }): SecretDetectionObjRes => {
          const returnObject = {
            page: response.page,
            items_page: response.items_page,
            data: response?.detections || [],
            total: response?.total || 100,
          };
          return returnObject;
        },
        providesTags: ['GetDetectedSecretsList'],
      }),
      getSecretByType: builder.query<
        any,
        { secretType: string; startDate: string; endDate: string }
      >({
        query: ({ secretType, startDate, endDate }) => ({
          url: `/secrets/detection/${secretType}`,
          params: {
            start_date: startDate,
            end_date: endDate,
            items_page: 1000000,
            page: 1,
          },
        }),
        providesTags: ['GetSecretByType'],
      }),
      updateSecret: builder.mutation<void, { status: string; id: string }>({
        query: (secret) => ({
          url: `/secrets/detections`,
          method: 'POST',
          body: { ...secret },
        }),
        invalidatesTags: ['GetDetectedSecretsList'],
      }),
      getSlackInfo: builder.query<any, void>({
        query: () => ({
          url: '/rules/slack/info',
        }),
      }),
      getJiraInfo: builder.query<any, void>({
        query: () => ({
          url: '/rules/jira/info',
        }),
      }),
      addConnectorAction: builder.mutation<any, any>({
        query: ({ policyName, action }) => {
          return {
            url: `/rules/policy/action`,
            params: {
              policy_name: policyName,
            },
            method: 'POST',
            body: action,
          };
        },
        invalidatesTags: [
          'CurrentConnections',
          'GetPolicyByName',
          'GetPolicyCriteriaByName',
        ],
      }),
      editConnectorAction: builder.mutation<any, any>({
        query: ({ policyName, action }) => {
          return {
            url: `/rules/policy-action`,
            params: {
              policy_name: policyName,
            },
            method: 'PUT',
            body: action,
          };
        },
        invalidatesTags: [
          'CurrentConnections',
          'GetPolicyByName',
          'GetPolicyCriteriaByName',
        ],
      }),
      deleteConnectorAction: builder.mutation<any, any>({
        query: ({ policyName, connectorId }) => {
          return {
            url: `/rules/policy/action`,
            params: {
              policy_name: policyName,
              connector_id: connectorId,
            },
            method: 'PATCH',
          };
        },
        invalidatesTags: [
          'CurrentConnections',
          'GetPolicyByName',
          'GetPolicyCriteriaByName',
        ],
      }),
      getSettingsInfo: builder.query<any, void>({
        query: () => ({
          url: '/admin/settings',
        }),
        providesTags: ['GetSettingsInfo'],
      }),
      updateSettings: builder.mutation<any, any>({
        query: ({ body }) => {
          return {
            url: `/admin/settings`,
            method: 'PUT',
            body,
          };
        },
        invalidatesTags: ['GetSettingsInfo'],
      }),
      getReportsList: builder.query<
        ReportObjRes,
        { searchQuery: string; pageNum: number }
      >({
        query: ({ searchQuery, pageNum }) => ({
          url: '/analytics/reports/list',
          params: {
            items_page: 30,
            page: pageNum,
            query: searchQuery,
          },
        }),
      }),
      sendCSVReportOnEmail: builder.mutation<
        void,
        { email: string; filePath: string }
      >({
        query: ({ email, filePath }) => ({
          url: `/analytics/reports/csv-report/${email}`,
          method: 'GET',
          params: {
            file_path: filePath,
          },
        }),
      }),
      deleteReport: builder.mutation<
        void,
        { hashKey: string; filePath: string }
      >({
        query: ({ hashKey, filePath }) => ({
          url: `/analytics/reports/delete`,
          method: 'DELETE',
          body: {
            hash_key: hashKey,
            file_path: filePath,
          },
        }),
      }),
      getContributors: builder.query<any, string>({
        query: (id) => ({
          url: `/oss/package/health/${id}`,
        }),
        providesTags: ['GetContributors'],
      }),
      getExposedRepositories: builder.query<
        ExposedRepositoriesObjRes,
        {
          id: string;
          pageNum: number;
          sortBy?: string;
          packageMgr: string;
          tableFilters: Filter;
        }
      >({
        query: ({ id, tableFilters, pageNum, sortBy, packageMgr }) => ({
          url: `/repositories/external/exposed-repositories/${id}`,
          params: {
            repo_status: tableFilters?.repo_status,
            severity: tableFilters?.severity,
            page: pageNum,
            sort_by: sortBy,
            package_manager: packageMgr,
          },
        }),
        providesTags: ['GetExposedRepositories'],
      }),
      getIdentityRiskReasons: builder.query<any, any>({
        query: (userId) => `users/${userId}/violations?page=1&items_page=10`,
      }),
      getRepositoryRiskReasons: builder.query<any, any>({
        query: (repoSlug) =>
          `repositories/${repoSlug}/violations?page=1&items_page=10`,
      }),
      getActivityAudit: builder.query<
        IdentityAuditActivityListRes,
        { id: string; pageNum: number; startDate: string; endDate: string }
      >({
        query: ({ id, pageNum, startDate, endDate }) => ({
          url: `/users/${id}/audit/pipeline`,
          params: {
            page: pageNum,
            items_page: 20,
            // start_date: startDate,
            // end_date: endDate,
          },
        }),
      }),
      triggerRemediation: builder.mutation<any, any>({
        query: ({ body }) => ({
          url: `/recommendations/trigger`,
          method: 'POST',
          body,
        }),
      }),
      getRecommendationsStatusList: builder.query<
        RecommendationsStatusRes,
        {
          pageNum: number;
        }
      >({
        query: ({ pageNum }) => ({
          url: 'recommendations/jobs',
          params: {
            page: pageNum,
            items_page: 10,
          },
        }),
      }),
      getIdentityActivitySeriesData: builder.query<any, any>({
        query: ({ id, startDate, endDate }) => ({
          url: `users/${id}/activities/timeseries`,
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        }),
        transformResponse: (
          response: any,
        ): { date: string; activities: string }[] => {
          const dataArray = Object.keys(response?.data).map((key: string) => {
            return {
              date: key,
              activities: response?.data[key]?.activities,
              suspicious_activities: response?.data[key]?.suspicious_activities,
            };
          });
          return dataArray.sort(
            (
              a: { date: string; activities: string },
              b: { date: string; activities: string },
            ) =>
              moment(a?.date, 'YYYY-MM-DD').toDate().getTime() -
              moment(b?.date, 'YYYY-MM-DD').toDate().getTime(),
          );
        },
      }),
      getTeamRepoRecommendation: builder.query({
        query: (id) => ({
          url: `users/teams/${id}/repo-recommendations`,
        }),
        providesTags: ['GetTeamRepoRecommendation'],
      }),
      getTeamMemberRecommendation: builder.query({
        query: (id) => ({
          url: `users/teams/${id}/member-recommendations`,
        }),
        providesTags: ['GetTeamMemberRecommendation'],
      }),
      getIdentityPipelineSeriesData: builder.query<any, any>({
        query: ({ id, startDate, endDate }) => ({
          url: `users/${id}/activities/pipeline/timeseries`,
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        }),
        transformResponse: (
          response: any,
        ): { date: string; activities: string }[] => {
          const dataArray = Object.keys(response?.data).map((key: string) => {
            return {
              date: key,
              activities: response?.data[key]?.activities,
              suspicious_activities: response?.data[key]?.suspicious_activities,
            };
          });
          return dataArray.sort(
            (
              a: { date: string; activities: string },
              b: { date: string; activities: string },
            ) =>
              moment(a?.date, 'YYYY-MM-DD').toDate().getTime() -
              moment(b?.date, 'YYYY-MM-DD').toDate().getTime(),
          );
        },
      }),
      getAssetActivitySeriesData: builder.query<any, any>({
        query: ({ id, startDate, endDate }) => ({
          url: `repositories/${id}/activities/timeseries`,
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        }),
        transformResponse: (
          response: any,
        ): { date: string; activities: string }[] => {
          const dataArray = Object.keys(response?.data).map((key: string) => {
            return {
              date: key,
              activities: response?.data[key]?.activities,
              suspicious_activities: response?.data[key]?.suspicious_activities,
            };
          });
          return dataArray.sort(
            (
              a: { date: string; activities: string },
              b: { date: string; activities: string },
            ) =>
              moment(a?.date, 'YYYY-MM-DD').toDate().getTime() -
              moment(b?.date, 'YYYY-MM-DD').toDate().getTime(),
          );
        },
      }),
      getAssetPipelineSeriesData: builder.query<any, any>({
        query: ({ id, startDate, endDate }) => ({
          url: `repositories/${id}/activities/pipeline/timeseries`,
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        }),
        transformResponse: (
          response: any,
        ): { date: string; activities: string }[] => {
          const dataArray = Object.keys(response?.data).map((key: string) => {
            return {
              date: key,
              activities: response?.data[key]?.activities,
            };
          });
          return dataArray.sort(
            (
              a: { date: string; activities: string },
              b: { date: string; activities: string },
            ) =>
              moment(a?.date, 'YYYY-MM-DD').toDate().getTime() -
              moment(b?.date, 'YYYY-MM-DD').toDate().getTime(),
          );
        },
      }),
      getComplianceOverviewList: builder.query<
        ComplianceOverviewDataRes,
        { pageNum: number; searchQuery: string }
      >({
        query: ({ pageNum, searchQuery }) => ({
          url: '/compliance/overview',
          params: {
            page: pageNum,
            items_page: 10,
            query: searchQuery,
          },
        }),
      }),
      getComplianceStandardList: builder.query<
        any,
        { complianceStandard: string }
      >({
        query: ({ complianceStandard }) => ({
          url: `/compliance/${complianceStandard}`,
        }),
      }),
      skipComplianceStandardPolicy: builder.mutation<
        any,
        { complianceStandard: string; policy: string; notes: string }
      >({
        query: ({ complianceStandard, policy, notes }) => ({
          url: `/compliance/${complianceStandard}/${policy}/skip`,
          method: 'POST',
          body: {
            notes,
          },
        }),
      }),
      getComplianceStandardPolicyDetails: builder.query<
        CompliancePolicyDetailsRes,
        { complianceStandard: string; compliancePolicyId: string }
      >({
        query: ({ complianceStandard, compliancePolicyId }) => ({
          url: `/compliance/${complianceStandard}/${compliancePolicyId}/details`,
        }),
      }),
      getComplianceStandardPolicyList: builder.query<
        any,
        {
          complianceStandard: string;
          compliancePolicyId: string;
          pageNum: number;
        }
      >({
        query: ({ complianceStandard, compliancePolicyId, pageNum }) => ({
          url: `/compliance/${complianceStandard}/${compliancePolicyId}/data`,
          params: {
            page: pageNum,
            items_page: 50,
          },
        }),
      }),
      ignoreComplianceStandardPolicyResource: builder.mutation<
        any,
        {
          complianceStandard: string;
          policy: string;
          notes: string;
          detection: any;
          ignore: boolean;
        }
      >({
        query: ({ complianceStandard, policy, notes, detection, ignore }) => ({
          url: `/compliance/${complianceStandard}/${policy}/ignore`,
          method: 'POST',
          body: {
            detection,
            ignore,
            notes,
          },
        }),
      }),
      enabledManualPolicyChecklist: builder.mutation<
        any,
        {
          complianceStandard: string;
          policy: string;
          enabled: boolean;
          name: string;
        }
      >({
        query: ({ complianceStandard, policy, name, enabled }) => ({
          url: `/compliance/${complianceStandard}/${policy}/checklist`,
          method: 'POST',
          body: {
            name,
            enabled,
          },
        }),
      }),
      addCustomRepositoryOwners: builder.mutation<GeneralResponseItem, any>({
        query: (query) => ({
          url: `/repositories/${query?.id}/owners`,
          method: 'POST',
          body: query?.payload,
        }),
        invalidatesTags: ['GetRepositoryContributors'],
      }),
      removeCustomRepositoryOwners: builder.mutation<GeneralResponseItem, any>({
        query: (query) => ({
          url: `/repositories/${query?.id}/owners`,
          method: 'POST',
          body: query?.payload,
        }),
        invalidatesTags: ['GetRepositoryContributors'],
      }),
      getGlobalSearchStarted: builder.query<any, any>({
        query: () => {
          return {
            url: '/analytics/search/start',
          };
        },
      }),
      getGlobalSearchNext: builder.mutation<any, any>({
        query: ({ payload }) => ({
          url: `analytics/search/next`,
          method: 'POST',
          body: payload,
        }),
      }),
      getGlobalSearchResult: builder.mutation<any, any>({
        query: ({ payload, params }) => ({
          url: `analytics/search/result`,
          method: 'POST',
          body: payload,
          params,
        }),
      }),
      requestRepositoryAccess: builder.mutation<any, any>({
        query: ({ payload }) => ({
          url: `/recommendations/get-repo-access`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetJITRequests'],
      }),
      requestRecurringRepositoryAccess: builder.mutation<any, any>({
        query: ({ payload }) => ({
          url: `/recommendations/get-repo-access-recurring`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetJITRequests'],
      }),
      getJITRequestsList: builder.query<any, any>({
        query: ({ pageNum, itemsPerPage, tableFilters }) => ({
          url: `/recommendations/repo-requests`,
          params: {
            page: pageNum,
            items_page: itemsPerPage || 1000000,
            ...tableFilters,
          },
        }),
        providesTags: ['GetJITRequests'],
      }),
      processRepositoryAccess: builder.mutation<any, any>({
        query: (payload) => ({
          url: `/recommendations/process-repo-access`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetJITRequests'],
      }),
      getPersonalJITRequestsList: builder.query<any, any>({
        query: ({ pageNum, itemsPerPage }) => ({
          url: `/recommendations/user/repo-requests`,
          params: {
            page: pageNum,
            items_page: itemsPerPage || 1000000,
          },
        }),
        providesTags: ['GetPersonalJITRequests'],
      }),
      getContainerSBOM: builder.query<any, any>({
        query: ({ repo, uid, format }) => ({
          url: `/repositories/sbom/container/${repo}`,
          params: {
            uid,
            format,
          },
        }),
      }),
      getBuildSBOM: builder.query<any, any>({
        query: ({ repo, format }) => ({
          url: `/repositories/sbom/build/${repo}`,
          params: {
            format,
          },
        }),
      }),
      getIdentityProvider: builder.query<any, void>({
        query: () => ({
          url: '/admin/identity-providers',
        }),
      }),
      addIdentityProvider: builder.mutation<any, any>({
        query: (payload) => ({
          url: `/admin/identity-provider`,
          method: 'POST',
          body: payload,
        }),
      }),
      getAdminGroups: builder.query<any, void>({
        query: () => ({
          url: '/admin/groups',
        }),
      }),
      addIdentityProviderMapper: builder.mutation<any, any>({
        query: ({ provider, mapperPayload }) => ({
          url: `/admin/identity-provider/${provider}/mapper`,
          method: 'POST',
          body: mapperPayload,
        }),
      }),
    }),
  });

export const defaultBaseApi = generateBaseApi(
  () => new Promise((resolve) => resolve('')),
);
