export enum FontSizes {
  fontSize8 = '8px',
  fontSize9 = '9px',
  fontSize10 = '10px',
  fontSize11 = '11px',
  fontSize12 = '12px',
  fontSize13 = '13px',
  fontSize14 = '14px',
  fontSize15 = '15px',
  fontSize16 = '16px',
  fontSize17 = '17px',
  fontSize18 = '18px',
}
