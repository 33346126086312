import { ReactNode, useContext, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { GlobalStyles } from '@mui/material';
import { paths } from 'constants/routes';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import 'react-toastify/dist/ReactToastify.css';
import { Navigation } from 'components/navigation/navigation';
import { useGetRoles } from 'store/modules/auth/auth.selectors';
import { Colors } from 'constants/colors';
import { Modals } from './components/modals/modals';
import { Toast } from './components/toast/toast';
import { useNavigate } from 'react-router-dom';
import { Search } from '@mui/icons-material';
import { ReactComponent as SignOutIcon } from 'assets/icons/sign-out.svg';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import './App.css';

interface Props {
  renderMainContent: () => ReactNode;
}

export function MainContent({ renderMainContent }: Props) {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const roles = useGetRoles();
  const apiContext = useContext(ApiContext);
  const [userLoginInfo, setUserLoginInfo] = useState<Record<any, any>>({});
  //const adminExists = roles?.findIndex((item) => item === 'admin') !== -1;
  const developerOnly = roles?.length === 1 && roles[0] === 'developer';
  const { data: userData } = apiContext.baseApi?.useGetUserInfoQuery();
  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      setUserLoginInfo(userData);
    }
    if (developerOnly && userLoginInfo?.login) {
      navigate(`${paths.identities}/self`);
    }
  }, [navigate, developerOnly, userLoginInfo, userData]);

  return (
    <>
      <CssBaseline />
      <GlobalStyles styles={{ body: { backgroundColor: Colors.tiber } }} />
      {roles.length > 0 && (
        <Box sx={{ display: 'flex', maxHeight: '100vh' }}>
          {CustomGlobalStyles}
          {!developerOnly ? <Navigation /> : null}
          <Modals />
          <Toast />
          <Box width="100%">
            <Box
              height="58px"
              display="flex"
              justifyContent="end"
              alignItems="center"
              sx={{
                width: '100%',
                pr: 3,
                background: Colors.blackPearl,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              <Box>
                <Search
                  sx={{
                    height: '20.5px',
                    cursor: 'pointer',
                    mt: 0.45,
                    mr: 2,
                  }}
                  onClick={() => navigate('/global-search')}
                />
              </Box>
              {userLoginInfo !== null &&
              userLoginInfo !== undefined &&
              Object.keys(userLoginInfo).length !== 0 ? (
                <Box onClick={() => navigate(`${paths.identities}/self`)}>
                  <AccountBoxIcon
                    height="20.5px"
                    style={{ cursor: 'pointer' }}
                  />
                </Box>
              ) : (
                ''
              )}
              <Box sx={{ ml: 3 }} onClick={() => keycloak.logout()}>
                <SignOutIcon height="20.5px" style={{ cursor: 'pointer' }} />
              </Box>
            </Box>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                bgcolor: Colors.tiber,
                padding: '30px 50px',
              }}
              data-testid="main-wrapper"
            >
              {renderMainContent()}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

const CustomGlobalStyles = (
  <GlobalStyles
    styles={{
      '.hide-resize-observer': {
        display: 'none !important',
      },
      '.MuiList-root': { backgroundColor: Colors.blackPearl },

      '.MuiPickersPopper-root': {
        '& .MuiPaper-root': {
          backgroundColor: Colors.blackPearl,
          color: Colors.white,
        },
      },
      '#dependencyTreeContainer': {
        path: {
          fill: 'none',
          stroke: '#919191',
        },

        text: {
          fontSize: '10px',
          fill: 'white',
        },
      },
      'input[data-autocompleted]': {
        'background-color': 'transparent !important;',
      },
      '.MuiCheckbox-root.Mui-checked': {
        color: '#5346F8 !important',
      },
    }}
  />
);
