import { Avatar, Box, Button, styled } from '@mui/material';
import { FontSizes } from 'constants/font-sizes';

import { Colors } from 'constants/colors';

export const ConnectorBox = styled(Box)(() => ({
  flexBasis: 'calc(50% - 25px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: Colors.darkDesaturatedCyan,
  padding: '15px 19px',

  '&:nth-last-of-type(2)': {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: Colors.darkDesaturatedCyan,
  },
  '&:nth-last-of-type(1)': {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: Colors.darkDesaturatedCyan,
  },
}));

export const StyledAvatar = styled(Avatar)(() => ({
  '&.MuiAvatar-root': {
    color: Colors.white,
    '& .MuiAvatar-img': {
      objectFit: 'contain',
    },
  },
}));

export const StyledPDFButton = styled(Button)(() => ({
  color: Colors.white,
  textTransform: 'capitalize',
  fontSize: FontSizes.fontSize12,

  '&.MuiButtonBase-root': {
    '&.Mui-disabled': {
      color: Colors.whiteTransparent04,
    },
  },
}));
